import axios from "axios";

export interface IProcurementItem {
  id: number;
  estimate_id: number;
  boq_item: string;
  item_description: string;
  item_image?: string;
  product_handle?: string;
  variant_handle?: string;
  vendor: any;
  hsn_code?: string;
  unit?: string;
  brand?: string;
  category_name?: string;
  unit_cost_price: number;
  mrp: number;
  unit_selling_price: number;
  landing_price: number;
  variant: number | null;
  total_price: number;
  quantity: number;
  margin?: number;
  tax_value?: number;
  procurement_poc_id: string;
  lead_time: number | null;
  remarks?: string;
  tax_type?: string;
  is_deleted: boolean;
  deleted_by_id: string | null;
  lead_id?: string;
  estimate_no: string;
  created_at: string;
  total_stock?: number;
  // selected_vendor_ware_house?: any;
  selected_vendor_ware_house_id?: number;
  selected_vendor_id?: number;
}

export interface IDeliveryItem {
  id: number;
  po_number: string;
  lead_id: string;
  created_by_id: string;
  type_of_order: string;
  delivery_date: string;
  branch_id: number;
  estimate_id: number;
  vendor: number;
  terms_and_condition_id: number;
  terms_and_condition_description: string;
  total_po_raised_amount: number;
  created_at: string;
  modified_at: string;
  is_decline: boolean;
  decline_by_id: string | null;
  is_deleted: boolean;
  deleted_by_id: string | null;
  is_revised: boolean;
  revised_by_id: string | null;
  gst_id: number;
  address: number;
  address_description: string;
  is_accepted: boolean;
  is_approved: boolean;
  is_pending: boolean;
  po_pdf: string;
  migration_id: unknown;
  porter_booking_time: string | null;
  procurement_poc_id: string;
  old_attachment: unknown;
  dispatch_time: string;
  po_delivery_status: string;
  remark: string | null;
  live_location_link: string | null;
  estimate_no: string;
}

export async function getCartItemDetails(params: Record<string, any>) {
  try {
    const response = await axios.get<Array<any>>(
      "https://api-dev2.materialdepot.in/apiV1/cart-item-details/",
      {
        params,
      }
    );
    return response.data;
  } catch (e) {
    return [];
  }
}

export async function editCartItemDetails(payload: any) {
  try {
    const response = await axios.post(
      "https://api-dev2.materialdepot.in/apiV1/cart-item-details/",
      payload
    );
    return response.data;
  } catch (e) {
    return null;
  }
}

export const fetchProcurementDashboardData = async (
  id?: string,
  search?: string,
  page?: number
) => {
  try {
    const response = await axios.get<any>("apiV1/procurement-poc-dashboard/", {
      params: { procurement_poc_id: id, search: search, page: page },
    });
    return response.data;
  } catch (e) {
    return null;
  }
};

export const fetchProcurementDeliveryData = async (id?: string) => {
  try {
    const response = await axios.get<Array<IProcurementItem>>(
      "apiV1/procurement-delivery-dashboard/",
      {
        params: id ? { procurement_poc_id: id } : {},
      }
    );
    return response.data;
  } catch (e) {
    return null;
  }
};
