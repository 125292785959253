import { Select, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";

interface PFIEditShipToAddressProps {
  removeShippingAddress: Function;
  setShipPincode: Function;
  setData: Function;
  client_addresses: Array<any>;
  data: any;
}
const { Option } = Select;

export const PFIEditShipToAddress = ({
  removeShippingAddress,
  setShipPincode,
  client_addresses,
  setData,
  data,
}: PFIEditShipToAddressProps) => {
  const [ship_to_address, setShipToAddress] = useState<any>(null);

  useEffect(() => {
    if (ship_to_address?.id) {
      setShipPincode(ship_to_address?.pincode);
      setData({
        ...data,
        ship_pincode: ship_to_address?.pincode,
        ship_address_description:
          ship_to_address?.address +
          ", " +
          ship_to_address?.city +
          ", " +
          ship_to_address?.pincode,
        ship_address: ship_to_address?.id,
      });
    }
  }, [ship_to_address]);

  // Filtered client addresses -- only show addresses which have been
  // updated within the last 30 days. If it is empty, just return all addresses.
  const filteredClientAddresses = useMemo(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
    const filtered = client_addresses.filter(
      (addr) => new Date(addr?.modified_at) > thirtyDaysAgo
    );
    if (filtered.length === 0) {
      return client_addresses;
    }

    return filtered;
  }, [client_addresses]);

  return (
    <section className="p-3 py-2 row mb-2">
      <div className="d-flex justify-content-between">
        <h6 className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
          Ship To
        </h6>
        <div className="">
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => removeShippingAddress()}
          >
            Remove
          </Tag>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Address Label</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {data?.ship_address?.name ?? ship_to_address?.name}
          </div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Address</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {/* <Form.Item name="ship_address"> */}
            <Select
              className="w-100"
              key={"ship_address_select"}
              value={data?.ship_address?.id ?? data?.ship_address}
              optionFilterProp="children"
              placeholder={"Address"}
              onChange={(e) => {
                setShipToAddress(
                  client_addresses.find((addr) => addr.id === e)
                );
              }}
            >
              {filteredClientAddresses?.map((each) => {
                return (
                  <Option key={each.id} value={each.id}>
                    {each?.name}
                  </Option>
                );
              })}
            </Select>
            {/* </Form.Item> */}
          </div>
        </div>
      </div>

      <div className="mt-3 row">
        <div className="col-4">Contact person</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {data?.ship_address?.contact_person_name ??
              ship_to_address?.contact_person_name}
          </div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Contact number</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {data?.ship_address?.contact_person_contact ??
              ship_to_address?.contact_person_contact}
          </div>
        </div>
      </div>

      <div className="mt-3 row">
        {data?.ship_address_description && (
          <>
            <div className="col-4">Selected address</div>
            <div className="col-8 d-flex">
              <div>:</div>
              <div className="w-100 px-2">
                {data?.ship_address_description ?? (
                  <>
                    {ship_to_address?.flat_no}, {ship_to_address?.address},{" "}
                    {ship_to_address?.city} {ship_to_address?.pincode}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
