import { Button, Pagination, Space } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  fetchClientAddresses,
  fetchClientDetails,
} from "../../../../apis/client.api";
import { fetchProFormaInvoices } from "../../../../apis/pro_forma_invoice.api";
import { getUserProperties } from "../../../../apis/user.api";
import { UserProperty } from "../../../../components/client/client_properties";
import { ClientPropertiesDetails } from "../../../../components/client/client_properties/details";
import { getFormattedDateAndTime } from "../../../../helpers/date_helper";
import { statusOptions } from "../../../dashboard";

export function MobileClientDetails() {
  const { userId } = useParams();
  const [details, setDetails] = useState<any>();
  const [addresses, setAddresses] = useState<any[]>([]);
  const [clientPIs, setClientPIs] = useState<any[]>([]);
  const [clientPIsCount, setClientPIsCount] = useState<number>(0);
  const [clientPIsPage, setClientPIsPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [clientUserProperties, setClientUserProperties] = useState<
    UserProperty[]
  >([]);

  const fetchUserProperties = () => {
    if (!userId) return;

    getUserProperties(userId).then((res) => {
      setClientUserProperties(res);
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchClientDetails(userId).then((res) => {
      setDetails(res);
      setLoading(false);
    });
    fetchUserProperties();
  }, [userId]);

  useEffect(() => {
    setLoading(true);
    fetchProFormaInvoices({
      client: userId,
      page: clientPIsPage,
      is_revised: false,
    }).then((res) => {
      setClientPIs(res.results);
      setClientPIsCount(res.count);
      setLoading(false);
    });
    fetchClientAddresses(userId).then((res) => {
      setAddresses(res);
    });
  }, [userId, clientPIsPage]);

  return (
    <div className="p-3">
      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}
      <div
        className="d-flex justify-content-between bg-white align-items-center"
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
        }}
      >
        <h4 className="fw-medium mb-3">Client Details</h4>
        <Space>
          <Link to={`/client/${userId}/edit`}>
            <Button type="primary">Edit</Button>
          </Link>
          <Link to="/clients">
            <Button type="link">Back</Button>
          </Link>
        </Space>
      </div>
      <div className="row p-3 mx-0 shadow-sm rounded">
        <div className="col">
          {details?.f_name && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Name </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.f_name}</div>
                </div>
              </div>
            </div>
          )}
          {details?.email && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Email </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.email}</div>
                </div>
              </div>
            </div>
          )}
          {details?.contact && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Phone</div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.contact}</div>
                </div>
              </div>
            </div>
          )}
          {details?.city && (
            <div className="row">
              <div className="col-4 th-font-weight-500">City </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.city}</div>
                </div>
              </div>
            </div>
          )}
          {details?.company_name && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Company Name </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.company_name}</div>
                </div>
              </div>
            </div>
          )}
          {details?.whatsapp_group_link && (
            <div className="row">
              <div className="col-4 th-font-weight-500">WA group link </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <a
                    href={details?.whatsapp_group_link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {details?.whatsapp_group_link}
                  </a>
                </div>
              </div>
            </div>
          )}
          {details?.gst_number && (
            <div className="row">
              <div className="col-4 th-font-weight-500">GST No. </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.gst_number}</div>
                </div>
              </div>
            </div>
          )}
          {details?.rupifi_activation_url && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Rupifi link </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{details?.rupifi_activation_url}</div>
                </div>
              </div>
            </div>
          )}
          {details?.created_at && (
            <div className="row">
              <div className="col-4 th-font-weight-500">Created at </div>
              <div className="col-7">
                <div className="d-flex">
                  <div className="pe-1">:</div>
                  <div>{getFormattedDateAndTime(details?.created_at)}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="p-3 shadow-sm rounded mt-4">
        <ClientPropertiesDetails properties={clientUserProperties} />
      </div>
      <div className="d-flex justify-content-between mt-4 px-3">
        <h5 className="">Addresses</h5>
        <Link to={`/client/${userId}/add-address`}>
          <Button type="primary">Add Address</Button>
        </Link>
      </div>
      {addresses?.length === 0 && (
        <div className="p-3 shadow-sm px-3 rounded mt-2 gy-2">No Addresses</div>
      )}
      {addresses?.map((address) => (
        <div className="p-3 shadow-sm rounded mt-2 px-3" key={address?.id}>
          <h6>{address?.name}</h6>
          <div style={{ fontSize: 13 }}>{address?.address}</div>
          <div className="mt-2">
            <Link to={`/client/${userId}/edit-address/${address?.id}`}>
              <Button size="small" type="default">
                Edit
              </Button>
            </Link>
          </div>
        </div>
      ))}
      <h5 className="mt-4 px-3" id="client-pis-header">
        Client PIs
      </h5>
      {clientPIs?.length === 0 && (
        <div className="p-3 shadow-sm px-3 rounded mt-2 gy-2">No PIs found</div>
      )}
      {clientPIs.map((pi) => (
        <div className="row p-3 shadow-sm mx-0 rounded mt-2 gy-2" key={pi?.id}>
          <div className="col-12">
            <h6>Order #{pi?.lead_id}</h6>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Created At
            </div>
            <div>{moment(pi?.created_at).format("DD/MM/YYYY HH:mm")}</div>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Total Amount
            </div>
            <div>₹ {pi?.total_estimate_amount}</div>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Status
            </div>
            <div>
              {
                statusOptions.find(
                  (status) => status?.value === pi?.estimate_status
                )?.label
              }
            </div>
          </div>
          <div className="col-6">
            <div style={{ fontSize: 11 }} className="fw-bold">
              Actions
            </div>
            <Space>
              <a
                href={`https://materialdepot.in/public-orders/${pi?.lead_id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary" size="small">
                  Open Tracking
                </Button>
              </a>
            </Space>
          </div>
        </div>
      ))}
      {clientPIsCount > 0 && (
        <Pagination
          total={clientPIsCount}
          current={clientPIsPage}
          onChange={(page) => {
            setClientPIsPage(page);
            // Scroll to header of PIs
            const header = document.getElementById("client-pis-header");
            if (header) {
              header.scrollIntoView({
                behavior: "smooth",
                block: "start",
              });
            }
          }}
          pageSize={10}
          showSizeChanger={false}
          style={{ textAlign: "center" }}
          className="mt-3"
        />
      )}
    </div>
  );
}
