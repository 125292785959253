import { Table } from "antd";
import moment from "moment";

export interface ClientVisitRecordsProps {
  clientDetails: any;
}

export function ClientVisitRecords({ clientDetails }: ClientVisitRecordsProps) {
  const columns = [
    {
      title: "Received By",
      key: "received_by",
      render: (data: any) => {
        return data?.sales_poc?.f_name;
      },
    },
    {
      title: "Date",
      key: "date",
      render: (data: any) => {
        return moment(data?.created_at).format("DD-MM-YYYY");
      },
    },
    {
      title: "Time",
      key: "time",
      render: (data: any) => {
        return moment(data?.created_at).format("hh:mm A");
      },
    },
  ];
  return (
    <div>
      <Table
        dataSource={clientDetails?.user_footfall_record ?? []}
        columns={columns}
        rowKey="id"
      />
    </div>
  );
}
