import { Select, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";

interface PFICreateBillToAddressProps {
  removeBillingAddress: Function;
  setData: Function;
  client_addresses: Array<any>;
  data: any;
  noRemove?: boolean;
  onSelectAddress?: (address: any) => void;
}
const { Option } = Select;

export const PFICreateBillToAddress = ({
  removeBillingAddress,
  client_addresses,
  setData,
  data,
  noRemove,
  onSelectAddress,
}: PFICreateBillToAddressProps) => {
  const [bill_to_address, setBillToAddress] = useState<any>(null);

  useEffect(() => {
    if (bill_to_address?.id) {
      setData({
        ...data,
        bill_address_description:
          bill_to_address?.address +
          ", " +
          bill_to_address?.city +
          ", " +
          bill_to_address?.pincode,
        address: bill_to_address?.id,
        bill_address: bill_to_address?.id,
      });
    }
  }, [bill_to_address]);

  // Filtered client addresses -- only show addresses which have been
  // updated within the last 30 days. If it is empty, just return all addresses.
  const filteredClientAddresses = useMemo(() => {
    const today = new Date();
    const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
    const filtered = client_addresses.filter(
      (addr) => new Date(addr?.modified_at) > thirtyDaysAgo
    );
    if (filtered.length === 0) {
      return client_addresses;
    }

    return filtered;
  }, [client_addresses]);

  return (
    <section className="p-3 py-2 row mb-2">
      <div className="d-flex justify-content-between">
        <h6 className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
          Bill To
        </h6>
        {!noRemove && (
          <div className="">
            <Tag
              style={{ cursor: "pointer" }}
              onClick={() => removeBillingAddress()}
            >
              Remove
            </Tag>
          </div>
        )}
      </div>
      <div className="mt-3 row">
        <div className="col-4">Name</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">{bill_to_address?.name ?? ""}</div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Address</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {/* <Form.Item name="bill_address"> */}
            <Select
              className="w-100"
              key={"bill_address_select"}
              value={bill_to_address?.id}
              optionFilterProp="children"
              placeholder={"Address"}
              onChange={(e) => {
                if (onSelectAddress) {
                  onSelectAddress(
                    client_addresses.find((addr) => addr.id === e)
                  );
                  return;
                }
                setBillToAddress(
                  client_addresses.find((addr) => addr.id === e)
                );
              }}
            >
              {filteredClientAddresses?.map((each) => {
                return (
                  <Option key={each.id} value={each.id}>
                    {each?.name}
                  </Option>
                );
              })}
            </Select>
            {/* </Form.Item> */}
          </div>
        </div>
      </div>

      <div className="mt-3 row">
        <div className="col-4">Contact person</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {bill_to_address?.contact_person_name}
          </div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">Contact number</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">
            {bill_to_address?.contact_person_contact}
          </div>
        </div>
      </div>
      <div className="mt-3 row">
        <div className="col-4">GST No.</div>
        <div className="col-8 d-flex">
          <div>:</div>
          <div className="w-100 px-2">{bill_to_address?.gst_no}</div>
        </div>
      </div>

      <div className="mt-3 row">
        {bill_to_address && (
          <>
            <div className="col-4">Selected address</div>
            <div className="col-8 d-flex">
              <div>:</div>
              <div className="w-100 px-2">
                {bill_to_address?.address}, {bill_to_address?.city}{" "}
                {bill_to_address?.pincode}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};
