import { DeleteOutlined, DownOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  MenuProps,
  Popconfirm,
  Table,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import PFIBillingDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_details/pfi_billing_details";
import PFIOrderDetails from "../../../components/pro_forma_invoice/pro_forma_invoice_details/pfi_order_details";

import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { fetchBranches } from "../../../apis/branch.api";
import { fetchClientDetails } from "../../../apis/client.api";
import { fetchEstimatePaymentUrl } from "../../../apis/payment.api";
import {
  editPI,
  fetchEstimateLogsByLeadId,
  fetchPIfromId,
  getPFIInvoices,
  removeInvoiceFromPI,
} from "../../../apis/pro_forma_invoice.api";
import DeliveryAddressDrawer from "../../../components/pro_forma_invoice/delivery_address_drawer";
import ProFormaLogCard from "../../../components/pro_forma_invoice/pro_forma_log_card";
import TaxAmount from "../../../components/pro_forma_invoice/tax_amount";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { Client } from "../../../interfaces/client.interface";
import styles from "./index.module.scss";

const ProFormaInvoiceDetails = () => {
  const { pi_id } = useParams();
  const navigate = useNavigate();
  const { hasPermission } = usePermissions();
  const userRole = localStorage.getItem("user_role");

  const [data, setData] = useState<any>({});
  const [logs, setLogs] = useState<Array<any>>([]);
  const [see_logs, setSeeLogs] = useState(false);
  const [branch_data, setBranchData] = useState<any>({});
  const [payment_url, setPaymentUrl] = useState("");
  const [copy_clipboard_select, setCopyClipboardSelect] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [invoices, setInvoices] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);

  const [delivery_address_drawer_visible, setDeliveryAddressDrawerVisible] =
    useState(false);

  const [client_data, setClientData] = useState<Client | null>(null);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            window.location.href = "/edit-pro-forma-invoice/" + pi_id;
          }}
        >
          Edit Pro Forma Invoice
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            window.open(
              "https://api-dev2.materialdepot.in/apiV1/estimate-pdf/" +
                pi_id +
                "/?image=true",
              "_blank"
            );
          }}
        >
          Download pdf
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          onClick={() =>
            window.open(
              `https://api-dev2.materialdepot.in/apiV1/estimate-pdf/${pi_id}/?image=true&b2b=true`,
              "_blank"
            )
          }
        >
          Download B2B Invoice
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "S.no",
      dataIndex: "index",
      render: (value: any, data: any, index: number) => <>{index + 1}</>,
    },
    {
      title: "BOQ Item",
      key: "boq_item",
      dataIndex: "boq_item",
      width: 200,
      render: (_: unknown, record: any) => {
        if (hasPermission("pi.replace_brand_with_private_label")) {
          return record["private_label_product_name"] ?? record["boq_item"];
        } else {
          return record["boq_item"];
        }
      },
    },
    hasPermission("pi.private_label_brand_name_column")
      ? {
          key: "private_label_product_name",
          title: "Private Label Name",
          dataIndex: "private_label_product_name",
          width: 200,
        }
      : {},
    {
      title: "MRP",
      key: "mrp",
      dataIndex: "mrp",
      width: 100,
    },
    {
      title: "Category",
      key: "category_name",
      dataIndex: "category_name",
      width: 100,
    },
    {
      title: "Quantity",
      key: "quantity",
      dataIndex: "quantity",
      width: 50,
    },
    {
      title: "Unit",
      key: "unit",
      dataIndex: "unit",
      width: 60,
    },
    // {
    //   title: "Brand",
    //   key: "brand",
    //   dataIndex: "brand",
    //   width: 130,
    //   render: (_: unknown, record: any) => {
    //     // if (hasPermission("pi.replace_brand_with_private_label")) {
    //     //   return record["private_label_brand_name"] ?? record["brand"];
    //     // } else {
    //     //   return record["brand"];
    //     // }
    //     return record["brand"];
    //   },
    // },
    hasPermission("pi.private_label_brand_name_column")
      ? {
          key: "private_label_brand_name",
          title: "Private Label Brand Name",
          dataIndex: "private_label_brand_name",
          width: 130,
        }
      : {},
    hasPermission("pi.view_vendor_column")
      ? {
          key: "vendor",
          title: "Vendor",
          render: (data: any) => {
            const vendors = data?.vendor_data ?? [];
            return vendors.map((vendor: any) => vendor?.name).join(", ");
          },
          width: 150,
        }
      : {},
    // {
    //   title: "Landing Price",
    //   key: "unit_selling_price",
    //   width: 100,
    //   render: (data: { [x: string]: string }) =>
    //     parseFloat(data["unit_selling_price"]),
    // }, {
    {
      title: "Selling Price",
      key: "unit_cost_price",
      width: 100,
      render: (data: { [x: string]: string }) =>
        parseFloat(data["unit_cost_price"]),
    },
    // {
    //   title: "Margin",
    //   key: "margin",
    //   width: 100,
    //   render: (data: { margin: number }) =>
    //     Number(data.margin / (1 + data.margin / 100)).toFixed(2),
    // },
    {
      title: "Tax",
      key: "tax",
      render: (data: { tax_value: any }) => {
        var tax = Number(data?.tax_value);
        return (
          <>
            <div>{tax}%</div>
          </>
        );
      },
    },
    {
      title: "Tax Type",
      key: "tax_type",
      render: (data: { tax_type: string }) => (
        <>
          {data?.tax_type === "igst" && "IGST"}
          {data?.tax_type === "cgst_sgst" && "CGST & SGST"}
          {data?.tax_type === "none" && "None"}
        </>
      ),
    },
    {
      title: "Lead time",
      key: "lead_time",
      render: (data: any) => {
        return (
          <div style={{ maxWidth: 60 }}>
            {/* {parseFloat(data["lead_time"]) > -1 ? (
              <>{data["lead_time"]} days</>
            ) : (
              <></>
            )} */}
            {data["lead_time"] && data["lead_time"] !== null && (
              <>{data["lead_time"]} days</>
            )}
          </div>
        );
      },
    },
    {
      title: "Remarks",
      key: "remarks",
      render: (data: any) => {
        return <div style={{ maxWidth: 200 }}> {data["remarks"]}</div>;
      },
    },
    {
      title: "Amount",
      key: "amount",
      render: (data: { [x: string]: number }) => {
        return data["total_price"];
      },
    },
  ];

  const getPIData = () => {
    fetchPIfromId(pi_id ?? "").then((res) => {
      setData({ ...res, po_items: res?.estimate_items });
      fetchEstimateLogsByLeadId(res?.lead_id).then((res) => {
        setLogs(res);
      });
      if (!res?.payment_link) {
        message.error({
          content:
            "Payment link not generated for this PI. Please revise the PI.",
          duration: 15,
          key: "payment_link_error",
        });
      }
    });
  };

  useEffect(() => {
    getPIData();
    fetchBranches().then((res) => {
      setBranchData(res);
    });
    fetchEstimatePaymentUrl(pi_id).then((res) => {
      setPaymentUrl(res);
    });
    fetchInvoicesOfPI();
    // fetchEstimateLogs(pi_id ?? "").then((res) => {
    //   setLogs(res);
    // });
  }, []);

  const fetchBasicDetails = (client_id: string) => {
    fetchClientDetails(client_id).then((res) => {
      setClientData(res);
    });
  };

  useEffect(() => {
    if (data?.client) {
      fetchBasicDetails(data?.client);
    }
  }, [data]);

  const fetchInvoicesOfPI = () => {
    setLoading(true);
    getPFIInvoices(pi_id).then((res) => {
      setInvoices(res);
      setLoading(false);
    });
  };

  const confirm = async (invoice_id: any) => {
    if (!loading) {
      setLoading(true);
      await removeInvoiceFromPI(invoice_id);
      setLoading(false);
    }
  };

  const cancel = () => {};
  const onDeliveryDetailsFinish = (values: any) => {
    setDeliveryAddressDrawerVisible(false);
    editPI(data?.id, {
      ...values,
      order_placed_time: moment(new Date()).add(5.5, "hours").toISOString(),
      estimate_status: "order_placed",
    }).then((res) => {
      navigate("/order-details/" + pi_id);
    });
  };

  return (
    <section className={`lighter-grey-color-bg`}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
        }}
      >
        <div className="d-flex justify-content-between">
          <div className="d-flex gap-4 align-items-center">
            <div className="primary-color-text th-font-size-20 th-font-weight-700">
              Pro Forma Details
            </div>
            <Link
              className="text-decoration-none"
              to={"/client/" + client_data?.id}
              target="_blank"
            >
              <div className=" th-font-weight-600 clover-green-color-text">
                Client :
                <span className=" th-font-weight-400">
                  {" "}
                  {client_data?.f_name}
                </span>
              </div>
            </Link>
            {/* <div className=" th-font-weight-600">
              Quotation No. :
              <span className=" th-font-weight-400"> {data?.estimate_no}</span>
            </div> */}
            <div className=" th-font-weight-600">
              Lead ID :
              <span className=" th-font-weight-400"> {data?.lead_id}</span>
            </div>
          </div>
          {hasPermission("pi.update") && (
            <div className="d-flex align-items-center">
              <Dropdown menu={{ items }} placement="bottomRight">
                <Button
                  type="primary"
                  className="th-font-weight-700 d-flex align-items-center"
                >
                  Actions
                  <DownOutlined />
                </Button>
              </Dropdown>
            </div>
          )}
        </div>
      </Header>
      <div className={styles.table_content}>
        <div className="m-4 p-4 px-5 white-color-bg">
          <div className="d-flex justify-content-between">
            <img
              alt="logo"
              src="/main_logo.svg"
              style={{ height: "80px" }}
              className="mt-2"
            />
            <div className="d-flex align-items-center">
              <Button onClick={() => setSeeLogs(true)}>See logs</Button>
            </div>
          </div>
          {/* <div className="my-4 p-3 ">
            <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
              Payment Link
            </div>
            <div>{payment_url}</div>
          </div> */}
          <div className="row my-4">
            <div className="col-xl-6 col-12">
              <PFIBillingDetails
                name={data?.gst?.label}
                address={data?.gst?.address}
                gst_no={data?.gst?.gst_no}
              />
            </div>
            <div className="col-xl-6 col-12">
              <PFIOrderDetails
                created_by={data?.created_by}
                assigned_to={data?.assigned_to?.f_name}
                client={client_data?.f_name ?? ""}
                lead_id={data?.lead_id}
                estimate_data={data}
                quotation_no={data?.estimate_no}
                status={data?.estimate_status}
                stage={data?.stage}
                bank_details={{
                  bank_account_number: branch_data?.bank_account_number,
                  bank_ifsc: branch_data?.bank_ifsc,
                  branch_upi: branch_data?.branch_upi,
                }}
                setDeliveryAddressDrawerVisible={
                  setDeliveryAddressDrawerVisible
                }
                getPIData={getPIData}
              />
            </div>
          </div>

          <Divider />
          <div className="row my-4">
            {data?.bill_address && (
              <div className="col-xl-6 col-12">
                <div className=" p-3 py-2 row mb-2">
                  <div className="th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                    Bill To
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Name </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Pincode </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.pincode}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact person{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.contact_person_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact number
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.bill_address?.contact_person_contact}</div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 row">
                    <div className="col-4">GST No.</div>
                    <div className="col-8 d-flex">
                      <div>:</div>
                      <div className="w-100 px-2">
                        {data?.bill_address?.gst_no}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {data?.ship_address && (
              <div className="col-xl-6 col-12">
                <div className=" p-3 py-2 row mb-2">
                  <div className=" th-font-size-16 th-font-weight-600 pb-3 primary-color-text">
                    Ship To
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Address Label{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.name}</div>
                      </div>
                    </div>
                  </div>

                  {data?.ship_address?.flat_no && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">Flat No.</div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>{data?.ship_address?.flat_no}</div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Address </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.address}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">Pincode </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.pincode}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact person{" "}
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.contact_person_name}</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Contact number
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>{data?.ship_address?.contact_person_contact}</div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-4 th-font-weight-500">
                      Selected address
                    </div>
                    <div className="col-7">
                      <div className="d-flex">
                        <div className="pe-1">:</div>
                        <div>
                          {data?.ship_address?.address},{" "}
                          {data?.ship_address?.city}{" "}
                          {data?.ship_address?.pincode}
                        </div>
                      </div>
                    </div>
                  </div>
                  {data?.ship_address?.google_map_address && (
                    <div className="row">
                      <div className="col-4 th-font-weight-500">
                        Google Map Address
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <div className="pe-1">:</div>
                          <div>
                            <a
                              href={data?.ship_address?.google_map_address}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {data?.ship_address?.google_map_address?.length >
                              50 ? (
                                <>
                                  {data?.ship_address?.google_map_address?.slice(
                                    0,
                                    50
                                  )}
                                  ...
                                </>
                              ) : (
                                <>{data?.ship_address?.google_map_address}</>
                              )}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div>
            {!copy_clipboard_select && (
              <Button
                onClick={() => {
                  setCopyClipboardSelect(true);
                }}
                className="mb-3"
                type="primary"
              >
                Select items to copy
              </Button>
            )}

            <div className="th-font-size-16 th-font-weight-600 mb-2">
              {copy_clipboard_select ? <> Select Items to raise PO</> : <> </>}
            </div>
            {copy_clipboard_select ? (
              <div className="d-flex gap-2 py-3">
                <Button
                  disabled={!hasSelected}
                  onClick={() => {
                    let items = data?.estimate_items?.filter((item: any) => {
                      return selectedRowKeys?.includes(item?.id);
                    });
                    let copy_text = "Lead ID:" + data?.lead_id + "\n\n";
                    items?.map((item: any) => {
                      copy_text +=
                        item?.private_label_product_name +
                        " --" +
                        item?.quantity +
                        " " +
                        item?.unit +
                        ", Brand: " +
                        item?.brand +
                        "\n";
                    });
                    navigator.clipboard.writeText(copy_text);
                    message.success("Copied to clipboard");
                    setCopyClipboardSelect(false);
                  }}
                  type="primary"
                >
                  Copy to Clipboard
                </Button>
                <Button
                  type="default"
                  onClick={() => {
                    setCopyClipboardSelect(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <></>
            )}
            {copy_clipboard_select ? (
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data?.estimate_items}
                scroll={{ x: "max-content" }}
                rowKey={(record) => record.id}
              />
            ) : (
              <Table
                columns={columns}
                dataSource={data?.estimate_items}
                scroll={{ x: "max-content" }}
                rowKey={(record) => record.id}
              />
            )}
          </div>
          <div className="row">
            <div className="col-xl-6 col-12">
              <div>
                <h6 className="th-font-weight-600">Payment Terms</h6>
                <div>
                  {data?.estimate_payment_terms?.map(
                    (each: any, ind: number) => (
                      <div key={ind}>
                        {each?.term}:{each?.percentage}%
                      </div>
                    )
                  )}
                </div>
              </div>
              <Divider />
              <div>
                <div>
                  <h6 className="th-font-weight-600">Terms and Conditions</h6>
                  <div>
                    <div className="mb-2">
                      {data?.terms_and_condition?.description}
                    </div>
                  </div>
                </div>
              </div>
              <Divider />

              {/* <div>
                <div className="">
                  <div>
                    <h6 className="th-font-weight-600">Documents</h6>{" "}
                    {"(Total files size should be less than 25MB)"}
                  </div>
                  {data?.documents?.map((document: any, index: any) => {
                    return (
                      <div style={{ padding: 8 }}>
                        <div
                          style={{
                            border: "1px #D1D0CE solid",
                            padding: 4,
                            cursor: "pointer",
                            fontWeight: 500,
                          }}
                          onClick={() => {
                            // openInNewTab(document?.file);
                          }}
                        >
                          {document?.file_name ??
                            document?.file?.replace(
                              "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/media/porject_files/",
                              ""
                            )}
                        </div>
                      </div>
                    );
                  })}
                  {data?.old_attachment !=== "" &&
                    JSON.parse(data?.old_attachment ?? "[]")?.map(
                      (document) => {
                        return (
                          <div style={{ padding: 8 }}>
                            <div
                              style={{
                                border: "1px #D1D0CE solid",
                                padding: 4,
                                cursor: "pointer",
                                fontWeight: 500,
                              }}
                              onClick={() => {
                                const extension = document.split(".").pop();
                                handlePdfDownload(
                                  document.replace(`.${extension}`, ""),
                                  extension
                                );
                                // openInNewTab(
                                //   "https://mysite-powo-pdfs.s3.ap-south-1.amazonaws.com/" +
                                //     document
                                // );
                              }}
                            >
                              {document ?? ""}
                            </div>
                          </div>
                        );
                      }
                    )}
                </div>
              </div> */}
            </div>
            <div className="col-xl-6 col-12">
              <div>
                <div className="d-flex justify-content-between">
                  {/* <div className="">Taxable Amount</div> */}
                  <div className="">
                    {/* ₹ {Number(getTotalTaxableAmount()).toFixed(2)} */}
                  </div>
                </div>
                <TaxAmount items={data?.estimate_items ?? []} />
              </div>
              <Divider />
              <div>
                {data?.estimate_additional_charges?.map(
                  (each: any, ind: number) => (
                    <>
                      <div className="d-flex justify-content-between" key={ind}>
                        <div className="">
                          {each?.charge_type}
                          {each?.charge_type
                            ?.toLowerCase()
                            ?.includes("discount")
                            ? " (as per item)"
                            : ` (@${each?.tax_value + "%"} ${each?.tax_type})`}
                        </div>
                        <div className="">
                          {Number(
                            Number(each?.amount) +
                              Number(each?.amount) *
                                Number(each?.tax_value) *
                                0.01
                          ).toFixed(2)}
                        </div>
                      </div>
                      {each.remark && each.remark != "" && (
                        <div>Remark: {each.remark}</div>
                      )}
                    </>
                  )
                )}
                {parseFloat(data?.coupon_discount_amount ?? "0") > 0 && (
                  <>
                    <div className="d-flex justify-content-between">
                      <div className="">Coupon Discount</div>
                      <div className="">
                        ₹ {Number(data?.coupon_discount_amount).toFixed(2)}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="th-font-size-12">Coupon Code</div>
                      <div className="th-font-size-12">
                        {data?.coupon_data?.coupon_code}
                      </div>
                    </div>
                  </>
                )}
              </div>
              <Divider />
              <div>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <h6>Total Amount</h6>
                  </div>
                  <div className="">
                    {Number(data?.total_estimate_amount)}
                    (Rounded off)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider />
          <div>
            <div className="th-font-size-18 th-font-weight-500">Invoices</div>
            <div className="d-flex gap-2 py-4 flex-wrap">
              {invoices?.map((invoice) => {
                return (
                  <div
                    onClick={() => {
                      window.open(invoice?.invoice_file, "_blank");
                    }}
                    className="p-3 px-4 border rounded-3 cursor-pointer"
                  >
                    <div className="th-font-size-16">
                      Invoice number : {invoice?.invoice_number}
                    </div>
                    <div className="th-font-size-16">
                      Invoice amount : {invoice?.invoice_value}
                    </div>
                    <div className="d-flex justify-content-between mt-3">
                      <Popconfirm
                        title="Delete the task"
                        description="Are you sure to delete this invoice?"
                        onConfirm={() => {
                          confirm(invoice?.id);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          className="d-flex align-items-center"
                          danger
                          loading={loading}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Popconfirm>
                      <Button
                        className=""
                        loading={loading}
                        onClick={() => {
                          window.open(
                            String(
                              invoice?.invoice_file ??
                                invoice?.invoice_file_link
                            ).replaceAll(
                              "mysite-powo-pdfs",
                              "materialdepotimages"
                            ),
                            "_blank"
                          );
                        }}
                      >
                        Open
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Basic Drawer"
        placement="right"
        onClose={() => {
          setSeeLogs(false);
        }}
        open={see_logs}
      >
        {logs?.map((log) => {
          return <ProFormaLogCard log={log} />;
        })}
      </Drawer>

      <DeliveryAddressDrawer
        data={data}
        visible={delivery_address_drawer_visible}
        onFinish={onDeliveryDetailsFinish}
        onClose={() => {
          setDeliveryAddressDrawerVisible(false);
        }}
      />
    </section>
  );
};

export default ProFormaInvoiceDetails;
