import { Input, message, Pagination, Radio, Space, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  editCartItemDetails,
  getCartItemDetails,
} from "../../apis/procurement.api";
import { fetchVendors } from "../../apis/vendor.api";
import { ProcurementItemForm } from "../../components/procurement/form";
import { StatusDot } from "../../components/client/status_dot";

export function ProcurementCartPage() {
  const [page, setPage] = useState(1);
  const [cartItems, setCartItems] = useState<Array<any>>([]);
  const [vendors, setVendors] = useState<Array<any>>([]);
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [total_cart_items, setTotalCartItems] = useState<number>(0);
  const [lead_time, setLeadTime] = useState<string>("not_filled");

  const getVendors = async () => {
    const vendors = await fetchVendors({});

    setVendors(vendors);
  };

  const fetchCartItems = async (params: any) => {
    setLoading(true);
    setPage(params?.page ?? 1);
    const res: any = await getCartItemDetails({
      lead_time: lead_time,
      search: search,
      page: params?.page ?? 1,
    });
    setLoading(false);
    setCartItems(res?.results);
    setTotalCartItems(res?.count);
  };

  const editCartItem = async (id: any, values: any) => {
    setLoading(true);

    const payload = {
      id: id,
      cart: values?.cart_id,
      lead_time: values?.lead_time,
      remarks: values?.remarks,
      selected_vendor_ware_house: values?.selected_vendor_ware_house,
      selected_vendor: values?.selected_vendor,
    };

    await editCartItemDetails(payload);
    setLoading(false);

    fetchCartItems({ page, search });

    message.success("Cart Item updated successfully");
  };

  const columns = [
    {
      key: "id",
      title: "Item ID",
      render: (item: any) => {
        return <div style={{ width: 55 }}>{item?.id}</div>;
      },
    },
    {
      key: "product_name",
      title: "Product Name",
      render: (item: any) => {
        return (
          <div style={{ width: 510 }}>
            {item?.cart?.variant?.private_label_product_name ??
              item?.cart?.variant?.product_name}
          </div>
        );
      },
    },
    {
      key: "cart_number",
      title: "Cart Number",
      render: (item: any) => {
        return <div style={{ width: 150 }}>{item?.cart_number}</div>;
      },
    },
    {
      key: "quantity",
      title: "Quantity",
      render: (item: any) => {
        return <div style={{ width: 50 }}>{item?.cart?.quantity}</div>;
      },
    },
    {
      key: "modified_at",
      title: "Created At",
      render: (item: any) => {
        return (
          <div style={{ width: 140 }}>
            {moment(item?.created_at).format("DD-MM-YYYY HH:mm")}
          </div>
        );
      },
    },
    {
      key: "edit",
      title: "Actions",
      render: (item: any) => {
        const preSelectedVendor = item?.cart?.variant?.vendor_data?.[0];
        const initData: Record<any, any> = {
          lead_time: item?.lead_time,
          selected_vendor_id:
            item?.selected_vendor?.id ?? preSelectedVendor?.id,
          selected_vendor_ware_house_id: item?.selected_vendor_ware_house?.id,
          remarks: item?.remarks,
        };
        return (
          <ProcurementItemForm
            onSubmit={(values) => {
              editCartItem(item?.id, values);
            }}
            vendors={vendors}
            initData={preSelectedVendor ? initData : {}}
            key={item?.id}
          />
        );
      },
    },
  ];

  useEffect(() => {
    fetchCartItems({ page, search });
  }, [search, lead_time]);

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <div className="mx-3 my-3 px-3 py-2 white-color-bg rounded">
      <div className="d-flex justify-content-between">
        <h3>Cart Availability Check</h3>
      </div>

      <div className="d-flex justify-content-between">
        <Radio.Group
          value={lead_time}
          onChange={(e) => {
            setLeadTime(e.target.value);
          }}
          style={{ marginBottom: 16 }}
        >
          <Radio.Button value="not_filled">Pending</Radio.Button>
          <Radio.Button value="">All</Radio.Button>
        </Radio.Group>
        <div>
          <Input.Search
            placeholder="Search"
            allowClear
            enterButton
            onSearch={(value) => {
              setLeadTime("");
              setSearch(value);
            }}
          />
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={cartItems}
        pagination={false}
        loading={loading}
        scroll={{ x: 768 }}
      />
      <Pagination
        current={page}
        total={total_cart_items ?? 1}
        showSizeChanger={false}
        pageSize={10}
        className="mt-3"
        onChange={(p) => {
          fetchCartItems({
            page: p,
            search,
            lead_time: lead_time,
          });
        }}
      />
    </div>
  );
}
