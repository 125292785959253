import {
  Button,
  Form,
  FormInstance,
  Input,
  message,
  Select,
  Space,
  Tabs,
} from "antd";
import React, { useEffect, useState } from "react";
import { fetchBranchMembers } from "../../../apis/branch.api";
import {
  addClient,
  assignClientBM,
  fetchClients,
} from "../../../apis/client.api";
import {
  getUserProperties,
  getUserPropertyList,
  incrementUserVisit,
  updateOrCreateUserProperty,
} from "../../../apis/user.api";
import { usePermissions } from "../../../custom_hooks/permission/permission_hook";
import { ClientProperties, UserInfoProperty } from "../client_properties";
const { Option } = Select;

export interface AddClientFormProps {
  onComplete?: () => void;
  onClose?: () => void;
  initContact?: string;
}

const AddClientForm = ({
  onComplete,
  onClose,
  initContact,
}: AddClientFormProps) => {
  const { hasPermission } = usePermissions();
  const [form] = Form.useForm();
  const [view, setView] = useState<"details" | "properties">("details");
  const [properties, setProperties] = useState<UserInfoProperty[]>([]);
  const [branchMembers, setBranchMembers] = useState<any[]>([]);
  const [preExistingUser, setPreExistingUser] = useState<any | null>(null);

  useEffect(() => {
    if (preExistingUser) {
      form.setFieldsValue({
        contact: preExistingUser?.contact,
        salutation: preExistingUser?.salutation,
        f_name: preExistingUser?.f_name,
        l_name: preExistingUser?.l_name,
        // email: preExistingUser?.email,
        bm: preExistingUser?.user_manager?.id,
      });

      getUserProperties(preExistingUser?.id).then((res) => {
        res?.forEach((prop: any) => {
          if (prop?.property?.name === "Source") {
            form.setFieldsValue({
              source: prop?.value,
            });
          }
          if (prop?.property?.name === "Requirements") {
            form.setFieldsValue({
              requirements: prop?.value?.split(","),
            });
          }
          if (prop?.property?.name === "Location") {
            form.setFieldsValue({
              location: prop?.value,
            });
          }
        });
      });
    }
  }, [preExistingUser]);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setBranchMembers(res);
    });
    getUserPropertyList().then((res) => {
      setProperties(res);
    });
  }, []);

  const [client, setClient] = useState<any>();

  const onFinish = async (updatedValues: any) => {
    const source = updatedValues?.source;
    const requirements = updatedValues?.requirements;
    const location = updatedValues?.location;
    const bm = updatedValues?.bm;

    // updatedValues.roles = 10;

    const payloadForPropertyUpdation = {
      user_id: null as any,
      properties: [] as Array<any>,
    };

    if (source) {
      const sourceProperty = properties?.find((p) => p?.name === "Source");
      payloadForPropertyUpdation.properties.push({
        property_id: sourceProperty?.id,
        value: source,
      });

      delete updatedValues?.source;
    }

    if (requirements) {
      const requirementsProperty = properties?.find(
        (p) => p?.name === "Requirements"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: requirementsProperty?.id,
        value: requirements.join(","),
      });

      delete updatedValues?.requirements;
    }

    if (location) {
      const locationProperty = properties?.find((p) => p?.name === "Location");
      payloadForPropertyUpdation.properties.push({
        property_id: locationProperty?.id,
        value: location,
      });

      delete updatedValues?.location;
    }

    const client = (await addClient({
      ...updatedValues,
      roles: 10,
      email: null,
    })) as any;

    if (!client) {
      message.error("Error adding client. Please check and try again");
      return;
    }

    if (hasPermission("client.footfall")) {
      let result = null;

      if (bm) {
        // If bm is already selected, get user ID
        const bmUserId = branchMembers?.find(
          (bmO) => String(bmO?.id) === String(bm)
        )?.user?.id;
        result = await incrementUserVisit(client?.id, bmUserId);
      } else {
        result = await incrementUserVisit(client?.id);
      }

      if (result?.message) {
        message.warning(result?.message);
      } else if (Boolean(result)) {
        message.success("Visit counter incremented successfully");
      } else {
        message.error("Error incrementing visit counter");
      }
    }

    setClient(client);

    payloadForPropertyUpdation.user_id = client?.id;

    if (payloadForPropertyUpdation.properties.length > 0) {
      updateOrCreateUserProperty(payloadForPropertyUpdation).then((res) => {
        console.log("Source updated", res);
      });
    }

    if (updatedValues?.bm) {
      const bmObj = branchMembers?.find((bm) => bm.id === updatedValues?.bm);
      assignClientBM(client?.id, bmObj?.id, bmObj?.user_permission);

      delete updatedValues?.bm;
    }

    // form.resetFields();
    if (onComplete) {
      onComplete();
    }
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable || preExistingUser}
      >
        Submit
      </Button>
    );
  };

  const getView = () => {
    if (view === "details") {
      return (
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          initialValues={{
            contact: initContact,
          }}
        >
          <Form.Item
            name="contact"
            label="Phone"
            rules={[
              {
                required: true,
                pattern: /^[1-9]\d{9}$/gi,
                message: "Enter a valid phone number",
              },
            ]}
          >
            <Input
              type="number"
              maxLength={10}
              onChange={async (ev) => {
                if (ev.target.value.length === 10) {
                  console.log("search", ev.target.value);
                  const clientDetails = await fetchClients({
                    params: {
                      search: ev.target.value,
                    },
                  });

                  if (clientDetails?.results?.length > 0) {
                    setPreExistingUser(clientDetails?.results?.[0]);
                  } else {
                    setPreExistingUser(null);
                  }
                }
              }}
            />
          </Form.Item>

          {preExistingUser && hasPermission("client.footfall") && (
            <Space className="mb-3">
              <div>User already exists. Increment visit counter?</div>
              <Button
                type="primary"
                onClick={async () => {
                  if (!hasPermission("client.footfall")) {
                    message.error(
                      "You don't have permission to increment visit"
                    );
                    return;
                  }

                  let result = null;
                  if (form.getFieldValue("bm")) {
                    // If bm is already selected, get user ID
                    const bmUserId = branchMembers?.find(
                      (bm) =>
                        String(bm?.id) === String(form.getFieldValue("bm"))
                    )?.user?.id;
                    result = await incrementUserVisit(
                      preExistingUser?.id,
                      bmUserId
                    );
                  } else {
                    result = await incrementUserVisit(preExistingUser?.id);
                  }

                  if (result?.message) {
                    message.warning(result?.message);
                  } else if (Boolean(result)) {
                    message.success("Visit counter incremented successfully");
                  } else {
                    message.error("Error incrementing visit counter");
                  }
                  onComplete?.();
                  onClose?.();
                }}
              >
                Add
              </Button>
            </Space>
          )}

          {/* <Form.List name="alternate_contact">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? "Alternate phone" : ""}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          pattern: /^[1-9]\d{9}$/gi,
                          message: "Enter a valid phone number",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="Phone" style={{ width: "80%" }} />
                    </Form.Item>
                    {fields.length > 0 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button mx-2"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: "60%" }}
                    icon={<PlusOutlined />}
                  >
                    Add alternate phone
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List> */}

          {/* <Form.List name="alternate_contact">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Alternate phone" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      pattern: /^[1-9]\d{9}$/gi,
                      message: "Enter a valid phone number",
                    },
                  ]}
                  noStyle
                >
                  <Input placeholder="Phone" style={{ width: "80%" }} />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button mx-2"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add alternate phone
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List> */}
          <div className="d-flex gap-2">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Enter a valid salutation",
                },
              ]}
              name="salutation"
              label="Salutation"
              style={{ width: 90 }}
            >
              <Select placeholder="Select salutation">
                <Option value="Mr">Mr</Option>
                <Option value="Mrs">Ms</Option>
                <Option value="Mx">Mx</Option>
              </Select>
            </Form.Item>
            <Form.Item
              required
              name="f_name"
              label="Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="l_name"
              label="Last Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
          </div>
          {/* <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "Enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item> */}

          {/* <Form.List name="alternate_email">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                label={index === 0 ? "Alternate email" : ""}
                required={false}
                key={field.key}
              >
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  noStyle
                >
                  <Input placeholder="Email" style={{ width: "80%" }} />
                </Form.Item>
                {fields.length > 0 ? (
                  <MinusCircleOutlined
                    className="dynamic-delete-button mx-2"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: "60%" }}
                icon={<PlusOutlined />}
              >
                Add alternate email
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List> */}
          {/* <Form.Item
        name="alternate_contact"
        label="Alternate phone"
        rules={[
          {
            pattern: /^[1-9]\d{9}$/gi,
            message: "Enter a valid phone number",
          },
        ]}
      >
        <Input type="number" maxLength={10} />
      </Form.Item>
      <Form.Item name="alternate_email" label="Alternate Email">
        <Input />
      </Form.Item> */}
          {/* <Form.Item
              label="Source"
              name={"channel"}
              rules={[
                {
                  required: true,
                  message: "Enter a valid user source",
                },
              ]}
            >
              <Select placeholder="Select user source">
                <Option value="whatsapp">Whatsapp/DMs</Option>
                <Option value="call">Call</Option>
                <Option value="store_walk_in">Store Walk In</Option>
                <Option value="email">Email</Option>
                <Option value="website_orders">Website Orders</Option>
              </Select>
            </Form.Item> */}
          <Form.Item
            name="source"
            label="Source"
            rules={[
              {
                required: true,
                message: "Enter a valid source",
              },
            ]}
          >
            <Select>
              {properties
                ?.find((p) => p?.name === "Source")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="requirements"
            label="Requirements"
            rules={[
              {
                required: true,
                message: "Enter a valid requirement",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Select requirements">
              {properties
                ?.find((p) => p?.name === "Requirements")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: "Enter a valid location",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
              label="Medium"
              name={"medium"}
              rules={[
                {
                  required: true,
                  message: "Enter a valid user medium",
                },
              ]}
            >
              <Select placeholder="Select user medium">
                <Option value="Fbig">Fbig</Option>
                <Option value="SEO">SEO</Option>
                <Option value="SEM">SEM</Option>
                <Option value="SMM">SMM</Option>
                <Option value="Referral">Referral</Option>
                <Option value="Offline">Offline</Option>
                <Option value="Newsletter">Email Marketing/Newsletter</Option>
              </Select>
            </Form.Item> */}
          <Form.Item name="bm" label="BM">
            <Select
              placeholder="Select BM"
              showSearch
              filterOption={(input, option) =>
                ((option?.children as unknown as string) ?? "")
                  .toLowerCase()
                  ?.indexOf(input.toLowerCase()) >= 0
              }
            >
              {branchMembers
                ?.filter((bm) => bm?.user_permission === 3)
                ?.map((bm) => {
                  return <Option value={bm.id}>{bm.user.f_name}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <SubmitButton form={form} />
            </Space>
          </Form.Item>
        </Form>
      );
    }

    if (view === "properties") {
      if (!client) {
        return (
          <div>
            <div className="alert alert-danger">
              Please fill basic details first to continue adding extra details
            </div>
          </div>
        );
      }

      if (client?.roles?.id) {
        client.roles = client?.roles?.id;
      }

      return (
        <ClientProperties
          userId={client?.id}
          onComplete={() => {
            message.success("Client extra details added successfully");
          }}
          isStudio={client?.roles === 8}
          client={client}
        />
      );
    }
  };

  return (
    <>
      <Tabs
        items={[
          {
            key: "details",
            label: "Basic Details",
          },
          {
            key: "properties",
            label: "+ Details",
          },
        ]}
        activeKey={view}
        onChange={(view) => setView(view as "details" | "properties")}
      />
      {getView()}
    </>
    // <Form
    //   form={form}
    //   name="validateOnly"
    //   layout="vertical"
    //   autoComplete="off"
    //   onFinish={onFinish}
    // >
    //   <Form.Item
    //     name="contact"
    //     label="Phone"
    //     rules={[
    //       {
    //         required: true,
    //         pattern: /^[1-9]\d{9}$/gi,
    //         message: "Enter a valid phone number",
    //       },
    //     ]}
    //   >
    //     <Input type="number" maxLength={10} />
    //   </Form.Item>

    //   <Form.List name="alternate_contact">
    //     {(fields, { add, remove }, { errors }) => (
    //       <>
    //         {fields.map((field, index) => (
    //           <Form.Item
    //             label={index === 0 ? "Alternate phone" : ""}
    //             required={false}
    //             key={field.key}
    //           >
    //             <Form.Item
    //               {...field}
    //               validateTrigger={["onChange", "onBlur"]}
    //               rules={[
    //                 {
    //                   pattern: /^[1-9]\d{9}$/gi,
    //                   message: "Enter a valid phone number",
    //                 },
    //               ]}
    //               noStyle
    //             >
    //               <Input placeholder="Phone" style={{ width: "80%" }} />
    //             </Form.Item>
    //             {fields.length > 0 ? (
    //               <MinusCircleOutlined
    //                 className="dynamic-delete-button mx-2"
    //                 onClick={() => remove(field.name)}
    //               />
    //             ) : null}
    //           </Form.Item>
    //         ))}
    //         <Form.Item>
    //           <Button
    //             type="dashed"
    //             onClick={() => add()}
    //             style={{ width: "60%" }}
    //             icon={<PlusOutlined />}
    //           >
    //             Add alternate phone
    //           </Button>
    //           <Form.ErrorList errors={errors} />
    //         </Form.Item>
    //       </>
    //     )}
    //   </Form.List>
    //   <Form.Item
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter a valid salutation",
    //       },
    //     ]}
    //     name="salutation"
    //     label="Salutation"
    //   >
    //     <Select placeholder="Select salutation">
    //       <Option value="Mr">Mr</Option>
    //       <Option value="Mrs">Ms</Option>
    //       <Option value="Mx">Mx</Option>
    //     </Select>
    //   </Form.Item>
    //   <Form.Item
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter a valid name",
    //       },
    //       {
    //         message: "No spaces allowed",
    //         pattern: /^[a-zA-Z]*$/gi,
    //       },
    //     ]}
    //     name="f_name"
    //     label="First Name"
    //   >
    //     <Input />
    //   </Form.Item>
    //   <Form.Item name="l_name" label="Last Name">
    //     <Input />
    //   </Form.Item>
    //   <Form.Item
    //     name="email"
    //     label="Email"
    //     rules={[
    //       {
    //         type: "email",
    //         message: "Enter a valid email",
    //       },
    //     ]}
    //   >
    //     <Input />
    //   </Form.Item>
    //   <Form.List name="alternate_email">
    //     {(fields, { add, remove }, { errors }) => (
    //       <>
    //         {fields.map((field, index) => (
    //           <Form.Item
    //             label={index === 0 ? "Alternate email" : ""}
    //             required={false}
    //             key={field.key}
    //             rules={[
    //               {
    //                 type: "email",
    //                 message: "Enter a valid email",
    //               },
    //             ]}
    //           >
    //             <Form.Item
    //               {...field}
    //               validateTrigger={["onChange", "onBlur"]}
    //               noStyle
    //             >
    //               <Input placeholder="Email" style={{ width: "80%" }} />
    //             </Form.Item>
    //             {fields.length > 0 ? (
    //               <MinusCircleOutlined
    //                 className="dynamic-delete-button mx-2"
    //                 onClick={() => remove(field.name)}
    //               />
    //             ) : null}
    //           </Form.Item>
    //         ))}
    //         <Form.Item>
    //           <Button
    //             type="dashed"
    //             onClick={() => add()}
    //             style={{ width: "60%" }}
    //             icon={<PlusOutlined />}
    //           >
    //             Add alternate email
    //           </Button>
    //           <Form.ErrorList errors={errors} />
    //         </Form.Item>
    //       </>
    //     )}
    //   </Form.List>
    //   <Form.Item
    //     label="User Type"
    //     name={"roles"}
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter a valid user type",
    //       },
    //     ]}
    //   >
    //     <Select
    //       placeholder="Select user type"
    //       onChange={(val) => {
    //         if (val === 8) {
    //           setIsStudio(true);
    //         } else {
    //           setIsStudio(false);
    //         }
    //       }}
    //     >
    //       <Option value={10}>Public</Option>
    //       <Option value={8}>Studio</Option>
    //     </Select>
    //   </Form.Item>
    //   {
    //     <>
    //       <Form.Item
    //         label="GST Number"
    //         name="gst_number"
    //         rules={[
    //           {
    //             required: isStudio,
    //             message: "Enter a valid gst number",
    //           },
    //           {
    //             pattern:
    //               /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
    //             message: "Enter a valid GST Number",
    //           },
    //         ]}
    //       >
    //         <Input />
    //       </Form.Item>
    //       {/* <Form.Item label="COA Reg. Number" name="coa_reg_no">
    //         <Input />
    //       </Form.Item> */}
    //       <Form.Item label="Company name" name="company_name">
    //         <Input required={isStudio} />
    //       </Form.Item>
    //       <Form.Item
    //         required={isStudio}
    //         label="Whatsapp group link"
    //         name="whatsapp_group_link"
    //         rules={[
    //           {
    //             type: "url",
    //             message: "Enter a valid url",
    //           },
    //         ]}
    //       >
    //         <Input />
    //       </Form.Item>
    //     </>
    //   }

    //   <Form.Item
    //     label="Source"
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter user source",
    //       },
    //     ]}
    //     name={"channel"}
    //   >
    //     <Select placeholder="Select user source">
    //       <Option value="whatsapp">Whatsapp/DMs</Option>
    //       <Option value="call">Call</Option>
    //       <Option value="store_walk_in">Store Walk In</Option>
    //       <Option value="email">Email</Option>
    //       <Option value="website_order">Website Orders</Option>
    //     </Select>
    //   </Form.Item>
    //   <Form.Item
    //     rules={[
    //       {
    //         required: true,
    //         message: "Enter user source",
    //       },
    //     ]}
    //     label="Medium"
    //     name={"medium"}
    //   >
    //     <Select placeholder="Select user medium">
    //       <Option value="Fbig">Fbig</Option>
    //       <Option value="SEO">SEO</Option>
    //       <Option value="SEM">SEM</Option>
    //       <Option value="SMM">SMM</Option>
    //       <Option value="Referral">Referral</Option>
    //       <Option value="Offline">Offline</Option>
    //       <Option value="Newsletter">Email Marketing/Newsletter</Option>
    //     </Select>
    //   </Form.Item>
    //   <Form.Item>
    //     <Space>
    //       <SubmitButton form={form} />
    //       <Button htmlType="reset">Reset</Button>
    //     </Space>
    //   </Form.Item>
    // </Form>
  );
};

export default AddClientForm;
