import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchClientDetails } from "../../../../apis/client.api";
import EditClientForm from "../../../../components/client/edit_client_form";

export function MobileClientEdit() {
  const { userId } = useParams();
  const [client, setClient] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientDetails(userId).then((res) => {
      setClient(res);
    });
  }, [userId]);

  return (
    <div className="p-3">
      <div className="d-flex justify-content-center">
        <h3 className="fw-bold mb-3">Edit Client</h3>
        {/* <div></div> */}
      </div>
      {client && (
        <EditClientForm
          client={client}
          fetchBasicDetails={() => {}}
          onClientEditDrawerClose={() => {
            navigate(`/client/${userId}`);
          }}
          fetchExtraDetails={() => {}}
        />
      )}
    </div>
  );
}
