import BranchDetails from "../pages/branch";
import { CartPage } from "../pages/cart";
import { ProcurementCartPage } from "../pages/carts";
import ClientDetails from "../pages/client_details";
import Clients from "../pages/clients";
import EstimateDashboard from "../pages/dashboard";
import ProcurementDelivery from "../pages/delivery";
import DeliveryList from "../pages/delivery/delivery_list";
import DeliveryTracking from "../pages/delivery/delivery_tracking";
import Finance from "../pages/finance";
import FinanceReport from "../pages/finance_report";
import MarginDashboard from "../pages/margin-dashboard";
import { MobileBMCarts } from "../pages/mobile/carts";
import { MobileCartDetails } from "../pages/mobile/carts/details";
import { MobileClientsList } from "../pages/mobile/clients";
import { MobileClientAdd } from "../pages/mobile/clients/add";
import { MobileClientAddressAdd } from "../pages/mobile/clients/address/add";
import { MobileClientAddressEdit } from "../pages/mobile/clients/address/edit";
import { MobileClientDetails } from "../pages/mobile/clients/details";
import { MobileClientEdit } from "../pages/mobile/clients/edit";
import { MobileOrders } from "../pages/mobile/orders";
import { MobileOrderDetails } from "../pages/mobile/orders/details";
import OrderDetails from "../pages/order/order_details";
import OrderList from "../pages/order/order_list";
import OrderRefund from "../pages/order/order_refund";
import POCreate from "../pages/po/po_create";
import PurchaseOrderDetails from "../pages/po/po_details";
import POEdit from "../pages/po/po_edit";
import POList from "../pages/po/po_list";
import ProFormaInvoiceCreate from "../pages/pro_forma_invoice/pro_forma_invoice_create";
import ProFormaInvoiceDetails from "../pages/pro_forma_invoice/pro_forma_invoice_details";
import ProFormaInvoiceEdit from "../pages/pro_forma_invoice/pro_forma_invoice_edit";
import ProFormaInvoicesClients from "../pages/pro_forma_invoice/pro_forma_invoices_clients";
import ProcurementManager from "../pages/procurement";
import Refunds from "../pages/refunds";
import { SalesLeaderboard } from "../pages/sales-leaderboard";
import UserProfile from "../pages/user_profile/user_profile_page";
import Vendors from "../pages/vendors";
import Reports from "../pages/vendors/report";

export const routes: Array<any> = [
  {
    path: `/`,
    Component: <Clients />,
    permission_needed: true,
    permission: "client.read",
  },
  {
    path: `/clients`,
    Component: <Clients />,
    permission_needed: true,
    permission: "client.read",
  },
  {
    path: "/procurement-cart",
    Component: <ProcurementCartPage />,
  },
  {
    path: `/cart`,
    Component: <CartPage />,
    permission_needed: true,
    permission: "cart.read",
  },
  {
    path: `/vendors`,
    Component: <Vendors />,
    permission_needed: true,
    permission: "vendor.read",
  },
  {
    path: `/orders`,
    Component: <OrderList />,
    permission_needed: true,
    permission: "order.read",
  },
  {
    path: `/client/:user_id`,
    Component: <ClientDetails />,
    permission_needed: true,
    permission: "client.read",
  },
  {
    path: `/po-list`,
    Component: <POList />,
    permission_needed: true,
    permission: "po.read",
  },
  {
    path: `/delivery-list`,
    Component: <DeliveryList />,
    permission_needed: true,
    permission: "delivery.read",
  },
  {
    path: `/create-po`,
    Component: <POCreate />,
    permission_needed: true,
    permission: "po.create",
  },
  {
    path: `/edit-po/:po_id`,
    Component: <POEdit />,
    permission_needed: true,
    permission: "po.update",
  },
  {
    path: `/sales-dashboard`,
    Component: <SalesLeaderboard />,
    permission_needed: true,
    permission: "sales.leaderboards",
  },
  {
    path: "/dashboard",
    Component: <EstimateDashboard />,
  },
  {
    path: "/reports",
    Component: <Reports />,
  },
  {
    path: "/procurement",
    Component: <ProcurementManager />,
  },
  {
    path: "/procurement-delivery",
    Component: <ProcurementDelivery />,
  },
  {
    path: "/finance-report",
    Component: <FinanceReport />,
  },
  {
    path: "/finance",
    Component: <Finance />,
  },
  {
    path: "/po-margin-dashboard",
    Component: <MarginDashboard />,
  },
  {
    path: `/order-details/:pi_id`,
    Component: <OrderDetails />,
    permission_needed: true,
    permission: "order.details",
  },
  {
    path: `/order-refund/:pi_id`,
    Component: <OrderRefund />,
    permission_needed: true,
    permission: "refund.create",
  },
  {
    path: `/refunds`,
    Component: <Refunds />,
    permission_needed: true,
    permission: "refund.read",
  },
  {
    path: `/po-details/:po_id`,
    Component: <PurchaseOrderDetails />,
    permission_needed: true,
    permission: "po.read",
  },

  {
    path: `/pro-forma-invoices`,
    Component: <ProFormaInvoicesClients />,
    permission_needed: true,
    permission: "pi.read",
  },
  {
    path: `/create-pro-forma-invoice/:client_id`,
    Component: <ProFormaInvoiceCreate />,
    permission_needed: true,
    permission: "pi.create",
  },
  {
    path: `/edit-pro-forma-invoice/:pi_id`,
    Component: <ProFormaInvoiceEdit />,
    permission_needed: true,
    permission: "pi.update",
  },
  {
    path: `/pro-forma-invoice-details/:pi_id`,
    Component: <ProFormaInvoiceDetails />,
    permission_needed: true,
    permission: "pi.read",
  },
  {
    path: `/delivery-tracking/:po_id`,
    Component: <DeliveryTracking />,
    permission_needed: true,
    permission: "delivery.read",
  },
  {
    path: `/branch-details`,
    Component: <BranchDetails />,
  },
  {
    path: `/user-profile`,
    Component: <UserProfile />,
  },
];

export const mobileRoutes: Array<any> = [
  {
    path: `/`,
    Component: <MobileClientsList />,
  },
  {
    path: `/clients`,
    Component: <MobileClientsList />,
  },
  {
    path: `/client/add`,
    Component: <MobileClientAdd />,
  },
  {
    path: `/client/:userId`,
    Component: <MobileClientDetails />,
  },
  {
    path: `/client/:userId/edit`,
    Component: <MobileClientEdit />,
  },
  {
    path: `/client/:userId/add-address`,
    Component: <MobileClientAddressAdd />,
  },
  {
    path: `/client/:userId/edit-address/:addressId`,
    Component: <MobileClientAddressEdit />,
  },
  {
    path: `/carts`,
    Component: <MobileBMCarts />,
  },
  {
    path: `/carts/:userId`,
    Component: <MobileCartDetails />,
  },
  {
    path: `/orders`,
    Component: <MobileOrders />,
  },
  {
    path: `/orders/:estimateId`,
    Component: <MobileOrderDetails />,
  },
];
