import {
  ShoppingCartOutlined,
  TableOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Link, Outlet, useLocation } from "react-router-dom";

export function MobileLayout() {
  const location = useLocation();

  return (
    <div className="">
      <Outlet />
      <div style={{ height: 70 }} />
      <div
        style={{ height: 70 }}
        className="flex-shrink-0 d-flex align-items-center justify-content-around bg-white shadow-sm fixed-bottom border-top"
      >
        <Link
          to="/clients"
          className={`text-center px-3 py-2 d-flex flex-column text-decoration-none justify-content-center align-items-center ${
            location.pathname === "/clients" ? "text-primary" : "text-muted"
          }`}
        >
          <UsergroupAddOutlined style={{ fontSize: 22 }} />
          <span className="fw-semibold" style={{ fontSize: 14 }}>
            Clients
          </span>
        </Link>
        <Link
          to="/carts"
          className={`text-center px-3 py-2 d-flex flex-column text-decoration-none justify-content-center align-items-center ${
            location.pathname === "/carts" ? "text-primary" : "text-muted"
          }`}
        >
          <ShoppingCartOutlined style={{ fontSize: 22 }} />
          <span className="fw-semibold" style={{ fontSize: 14 }}>
            Carts
          </span>
        </Link>
        <Link
          to="/orders"
          className={`text-center px-3 py-2 d-flex flex-column text-decoration-none justify-content-center align-items-center ${
            location.pathname === "/orders" ? "text-primary" : "text-muted"
          }`}
        >
          <TableOutlined style={{ fontSize: 22 }} />
          <span className="fw-semibold" style={{ fontSize: 14 }}>
            Orders
          </span>
        </Link>
      </div>
    </div>
  );
}
