import { Button, Input, Pagination } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { clientBMCarts } from "../../../apis/client.api";

export function MobileBMCarts() {
  const [carts, setCarts] = useState([]);
  const [totalClients, setTotalClients] = useState(0);
  const [clientDetailsForPage, setClientDetailsForPage] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    setLoading(true);
    clientBMCarts({
      page,
      search,
      pageSize,
    }).then((res) => {
      setCarts(res?.data ?? []);
      setTotalClients(res?.total ?? 0);
      setClientDetailsForPage(res?.user_details ?? {});
      setLoading(false);
    });
  }, [page, search, pageSize]);

  const getUserDetails = (userId: string) => {
    return clientDetailsForPage?.find((client) => client.id === userId);
  };

  const groupedCarts = useMemo(() => {
    return carts.reduce((acc: any, cart: any) => {
      if (!acc[cart.user]) {
        acc[cart.user] = {
          carts: [],
          totalPrice: 0,
          totalItems: 0,
        };
      }

      acc[cart.user].carts.push(cart);
      acc[cart.user].totalPrice +=
        Number(cart?.quantity) * Number(cart?.variant?.md_price_gst);
      acc[cart.user].totalItems += Number(cart?.quantity);

      return acc;
    }, {});
  }, [carts]);

  return (
    <div className="p-3">
      <div className="text-center">
        <h3 className="fw-bold mb-3">Carts</h3>
      </div>
      <div className=" rounded-bottom mb-4">
        <Input.Search
          onSearch={(value) => {
            setSearch(value);
          }}
          allowClear
          placeholder="Search with Client Name or Phone No..."
          size="large"
          className="rounded-pill"
        />
      </div>
      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}
      {carts.length === 0 && !loading && (
        <div className="text-center alert alert-warning">No Carts Found</div>
      )}
      <div>
        {Object.keys(groupedCarts).map((userId) => (
          <div key={userId} className="rounded p-3 mb-3 shadow-sm">
            <h5>
              {getUserDetails(userId)?.f_name} {getUserDetails(userId)?.l_name}
            </h5>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: ".5rem 0",
              }}
            >
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Cart Number
                </div>
                {getUserDetails(userId)?.user_info?.cart_number}
              </div>
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Total Price
                </div>
                Rs. {groupedCarts[userId].totalPrice?.toFixed(2)}
              </div>
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Total Items
                </div>
                {groupedCarts[userId].totalItems}
              </div>
              <div>
                <div
                  className="text-muted fw-semibold"
                  style={{ fontSize: 12 }}
                >
                  Total Products
                </div>
                {groupedCarts[userId].carts.length}
              </div>
            </div>
            <div className="mt-3 d-flex align-items-center gap-2">
              <Link to={`/carts/${userId}`}>
                <Button size="small" type="default">
                  View Details
                </Button>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center">
        <Pagination
          current={page}
          pageSize={pageSize}
          total={totalClients}
          onChange={(page) => setPage(page)}
          onShowSizeChange={(current, size) => setPageSize(size)}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}
