import { Button, Form, Input, Segmented, Select } from "antd";
import { useState } from "react";
import { addClient } from "../../../apis/client.api";

const userTypesTabs = [
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Business",
    value: "business",
  },
];

export interface ClientAddMobileProps {
  onBack: () => void;
}

export function ClientAddMobile({ onBack }: ClientAddMobileProps) {
  const [userType, setUserType] = useState<"individual" | "business">(
    "individual"
  );

  const onFinish = (values: any) => {
    addClient({
      ...values,
      roles: "10",
    }).then(() => {
      onBack();
    });
  };

  return (
    <Form onFinish={onFinish}>
      <div className="d-flex justify-content-between">
        <div className="font-medium mb-3" style={{ fontSize: 18 }}>
          Basic Information
        </div>
        <div>
          <Button type="link" onClick={onBack}>
            Cancel
          </Button>
        </div>
      </div>
      <Form.Item
        label="Phone"
        rules={[
          { required: true, message: "Please input your phone number!" },
          {
            pattern: new RegExp(/^[0-9]{10}$/),
            message: "Please enter a valid phone number",
          },
        ]}
        name="contact"
      >
        <Input type="tel" />
      </Form.Item>
      <div className="d-flex justify-content-center w-100">
        <Segmented
          options={userTypesTabs}
          value={userType}
          onChange={(v) => setUserType(v as any)}
        />
      </div>
      {userType === "business" && (
        <Form.Item label="GST No." name="gst_no">
          <input name="gst_no" placeholder="GST No." className="form-control" />
        </Form.Item>
      )}
      <label className="d-block th-fontSize-12 th-fontWeight-500 text-muted mb-1">
        Name
      </label>
      <div className="d-flex gap-1">
        <Form.Item name="salutation">
          <Select
            options={[
              { label: "Mr.", value: "mr" },
              { label: "Ms.", value: "ms" },
              { label: "Mx.", value: "mx" },
            ]}
            placeholder="Title"
            onChange={(v) => console.log(v)}
            style={{ width: 80, maxWidth: 80 }}
          />
        </Form.Item>
        <Form.Item name="f_name">
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item name="l_name">
          <Input placeholder="Last Name" />
        </Form.Item>
      </div>
      <Form.Item label="Email" name="email">
        <Input type="email" />
      </Form.Item>

      <Button htmlType="submit" className="w-100" type="primary">
        Save
      </Button>
    </Form>
  );
}
