import { message } from "antd";
import axios from "axios";
import { baseURL } from "..";
import { errorActions } from "../helpers/auth_helper";

export async function addClient(values: any) {
  try {
    return await axios
      .post("https://api-dev2.materialdepot.in/apiV1/client-user/", {
        ...values,
      })
      .then((response) => {
        if (response.data) {
          message.success("Client added successfully");
          return response.data;
        }
      })
      .catch((error) => {
        errorActions(
          error,
          "Error occurred. Please check if client is already present"
        );
      });
  } catch (error) {}
}

export async function editClient(values: any, id: string) {
  console.log("addCLient", values);
  try {
    await axios
      .put("apiV1/client-user/" + id + "/", {
        ...values,
      })
      .then((response) => {
        if (response.data) {
          message.success("Client edited successfully");
          return response.data;
        }
      })
      .catch((error) => {
        errorActions(error, "Error editing client. Please try again");
        return;
      });
  } catch (error) {}
}

export const fetchClients = async (params: any) => {
  console.log("fetchClients", params);
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/client-user/`, {
        ...params,
      })
      .then((res) => {
        // console.log(res.status, "this is the response");

        if (res) {
          const clients = res?.data;
          return clients;
        }
      })

      .catch((error) => {
        if (error) {
          console.log("error found");
          errorActions(
            error,
            "Error fetching clients. Please check and try again"
          );
          return;
        }
      });
  } catch (error) {}
};

export const fetchClientCart = async (user_id: any) => {
  console.log("data", user_id);

  try {
    const response = await fetch(`${baseURL}apiV1/sale-cart/?user=` + user_id, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    const data = await response.json();
    // console.log("data", data);
    const cart = data;
    return cart;
  } catch (error) {
    message.error("Error fetching client cart. Please check and try again");
    return null;
  }
};

export const fetchClientDetails = async (user_id: any) => {
  console.log("user_id", user_id);
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/client-user/` + user_id)
      .then((res) => {
        const clients = res?.data;
        return clients;
      })
      .catch((e) => {
        errorActions(
          e,
          "Error fetching client details. Please check and try again"
        );
        return;
      });
  } catch (error) {}
};

export const fetchClientAddresses = async (user_id: any) => {
  try {
    return axios
      .get(
        `https://api-dev2.materialdepot.in/apiV1/client-user-address/?user=` +
          user_id
      )
      .then((res) => {
        if (res) {
          const address = res?.data;
          return address;
        }
      })
      .catch((e) => {
        errorActions(
          e,
          "Error fetching client addresses. Please check and try again"
        );
        return;
      });
  } catch (error) {}
};

export const editClientAddress = async (address: any, address_id: any) => {
  try {
    return axios
      .put(
        `https://api-dev2.materialdepot.in/apiV1/client-user-address/${address_id}/`,
        {
          ...address,
        }
      )
      .then((res) => {
        if (res.data) {
          message.success("Client address edited successfully");
        }
        const address = res?.data;
        return address;
      })
      .catch((error) => {
        errorActions(error, "Error editing client address. Please try again");
        return;
      });
  } catch (error) {}
};

export const addClientAddress = async (address: any, user_id: any) => {
  try {
    return axios
      .post(`https://api-dev2.materialdepot.in/apiV1/client-user-address/`, {
        ...address,
        user: user_id,
      })
      .then((res) => {
        if (res.data) {
          message.success("Client address added successfully");
        }
        const address = res?.data;
        return address;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error adding client address. Please check and try again"
        );
        return;
      });
  } catch (error) {}
};

export const addClientAlternateContact = async (contact: any, user_id: any) => {
  try {
    return axios
      .post(`https://api-dev2.materialdepot.in/apiV1/user-alternate/`, {
        ...contact,
        user: user_id,
      })
      .then((res) => {
        if (res.data) {
          message.success("Client alternate contact added successfully");
        }
        const address = res?.data;
        return address;
      })
      .catch((error) => {
        errorActions(
          error,
          "Error adding client alternate contact. Please check and try again"
        );
        return;
      });
  } catch (error) {}
};

export async function editClientAlternateContact(values: any, id: string) {
  try {
    await axios
      .put("apiV1/user-alternate/" + id + "/", {
        ...values,
      })
      .then((response) => {
        if (response.data) {
          message.success("Client alternate successfully");
          return response.data;
        }
      })
      .catch((error) => {
        errorActions(error, "Error editing client. Please try again");
        return;
      });
  } catch (error) {}
}

export const fetchClientAlternateContact = async (user_id: any) => {
  try {
    return axios
      .get(
        `https://api-dev2.materialdepot.in/apiV1/user-alternate/?user=` +
          user_id
      )
      .then((res) => {
        if (res) {
          const contacts = res?.data;
          return contacts;
        }
      })
      .catch((e) => {
        errorActions(
          e,
          "Error fetching client addresses. Please check and try again"
        );
        return;
      });
  } catch (error) {}
};

export const addClientDocuments = async (formData: any) => {
  try {
    return axios
      .post("https://api-dev2.materialdepot.in/apiV1/project-files/", formData)
      .then((res) => {
        if (res.data) {
          message.success("Client Documents added successfully");
        }
        return {
          document: res?.data?.file,
          id: res?.data?.id,
          file_name: res?.data?.file_name,
        };
      })
      .catch((e) => {
        errorActions(e, "Error adding client documents. Please try again");
        return;
      });
  } catch (error) {}
};

export const bookSlot = async (data: {
  name: string;
  contact: string;
  date?: string;
  email?: string;
}) => {
  const response = await fetch(
    "https://api-dev2.materialdepot.in/apiV1/book-slot/",
    {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(data),
    }
  );

  return await response.json();
};

export const editSlot = async (data: { [key: string]: unknown }) => {
  if (!data?.id) return;

  return axios
    .put("apiV1/book-a-slot/" + data.id, data)
    .then((res) => {
      if (res.data) {
        message.success("Appointment updated successfully");
      }
      return res.data;
    })
    .catch((e) => {
      errorActions(e, "Error updating appointment. Please try again");
      return;
    });
};

export const cancelSlot = async (booking_number: string) => {
  if (!booking_number) return;

  return axios
    .get("https://api-dev2.materialdepot.in/apiV1/cancel-slot/", {
      params: {
        booking_number,
        send_message: "True",
      },
    })
    .then((res) => {
      if (res.data) {
        message.success("Appointment cancelled successfully");
      }
      return res.data;
    })
    .catch((e) => {
      errorActions(e, "Error updating appointment. Please try again");
      return;
    });
};

export const getUserCartCount = async (
  params: Record<string, string | number>
) => {
  return axios
    .get("https://api-dev2.materialdepot.in/apiV1/user-cart-count/", {
      params,
    })
    .then((res) => res.data)
    .catch((e) => {
      errorActions(e, "Error fetching cart count. Please try again");
      return;
    });
};

export const createFootfallRecord = async (data: {
  name: string;
  contact: number;
  source: string;
  pov: string;
}) => {
  return axios
    .post("https://api-dev2.materialdepot.in/apiV1/footfall-record/", data)
    .then((res) => {
      if (res.data) {
        message.success("Footfall record created successfully");
      }
      return res.data;
    })
    .catch((e) => {
      console.log(e);
      errorActions(e, "Error creating footfall record. Please try again");
      return;
    });
};

export async function assignClientBM(
  client_id: string,
  manager_organisation_id: string | number,
  role: string | number
) {
  try {
    const response = await axios.post(
      `https://api-dev2.materialdepot.in/apiV1/user-manager-assignment/`,
      {
        user: client_id,
        manager: manager_organisation_id,
        role: role,
        status: "true",
      }
    );

    return response.data;
  } catch (error) {
    errorActions(
      error as any,
      "Error assigning client to BM. Please try again"
    );
  }
}

export async function clientBMCarts({
  page,
  search,
  pageSize,
}: {
  page?: number;
  search?: string;
  pageSize?: number;
} = {}) {
  try {
    const response = await axios.get(
      `https://api-dev2.materialdepot.in/apiV1/bm-carts/`,
      {
        params: {
          client_search: search,
          page: page,
          page_size: pageSize,
        },
      }
    );

    return response.data;
  } catch (e) {
    return null;
  }
}
