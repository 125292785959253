import { Button, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchClientAddresses } from "../../../../../apis/client.api";
import EditClientAddressForm from "../../../../../components/client/edit_client_address_form";

export function MobileClientAddressEdit() {
  const { addressId, userId } = useParams();
  const [address, setAddress] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (!userId || !addressId) return;

      const addresses = await fetchClientAddresses(userId);
      const address = addresses.find(
        (address: any) => String(address.id) === String(addressId)
      );

      setAddress(address);
    })();
  }, [userId, addressId]);

  if (!address) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold mb-3">Edit Address</h3>
        <Button
          type="link"
          size="small"
          onClick={() => navigate(`/client/${userId}`)}
        >
          Back
        </Button>
      </div>
      <EditClientAddressForm
        address={address}
        fetchAddresses={() => {}}
        onClientEditAddressClose={() => {
          navigate(`/client/${userId}`);
        }}
      />
    </div>
  );
}
