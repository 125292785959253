import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import WebsiteLayout from "../components/layout";
import { MobileLayout } from "../components/layout/mobile-layout";
import ProtectedRoute from "./protected_route";
import { mobileRoutes, routes } from "./routes";

const LayoutRoutes = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isMobile) {
    return (
      <Routes>
        {mobileRoutes.map(
          ({ path, Component, permission_needed, permission }, i) => (
            <Route element={<MobileLayout />} key={i}>
              {permission_needed ? (
                <Route
                  path={path}
                  element={
                    <ProtectedRoute permission={permission}>
                      {Component}
                    </ProtectedRoute>
                  }
                />
              ) : (
                <Route path={path} element={Component} />
              )}
            </Route>
          )
        )}
      </Routes>
    );
  }

  return (
    <Routes>
      {routes.map(({ path, Component, permission_needed, permission }, i) => (
        <Route element={<WebsiteLayout />} key={i}>
          {permission_needed ? (
            <Route
              path={path}
              element={
                <ProtectedRoute permission={permission}>
                  {Component}
                </ProtectedRoute>
              }
            />
          ) : (
            <Route path={path} element={Component} />
          )}
        </Route>
      ))}
    </Routes>
  );
};

export default LayoutRoutes;
