import { Button } from "antd";
import imageCompressor from "browser-image-compression";
import { useEffect, useRef, useState } from "react";
import { UserInfoProperty } from ".";
import { uploadImage } from "../../../apis/misc.api";

export interface AttachmentInputFormInptProps {
  property: UserInfoProperty;
  onChange: (value: any) => void;
}

export function AttachmentInput({
  property,
  onChange,
}: AttachmentInputFormInptProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = useState<string | null>(null);

  useEffect(() => {
    if (!fileInputRef.current) {
      return;
    }

    const current = fileInputRef.current;

    current.addEventListener("change", async () => {
      if (!current?.files) {
        return;
      }

      const file = current.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreview(reader.result as string);
      };

      reader.readAsDataURL(file);

      // Compress the image
      const compressed = await imageCompressor(file, {
        maxSizeMB: 1,
        useWebWorker: true,
      });

      const formData = new FormData();
      formData.append("file", compressed);

      const url = await uploadImage(formData);

      onChange(url);
    });

    return () => {
      current?.removeEventListener("change", () => {});
    };
  }, [fileInputRef]);

  return (
    <>
      <div style={{ marginBottom: 10 }}>
        {preview && (
          <img
            src={preview}
            alt="Preview"
            style={{ width: 100, height: 100, objectFit: "cover" }}
          />
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
      />
      <Button
        onClick={() => {
          fileInputRef.current?.click();
        }}
      >
        {property.name} Upload
      </Button>
    </>
  );
}
