import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  getAddressFromPincode,
  getLatLongFromGooGlLink,
} from "../../../apis/misc.api";
import { editVendor, getVendor } from "../../../apis/vendor.api";
import { IVendor } from "../../../interfaces/vendor.interface";

export interface VendorEditFormProps {
  vendor: IVendor;
  refresh: () => unknown;
  onClose: () => unknown;
}
export function VendorEditForm({
  vendor,
  refresh,
  onClose,
}: VendorEditFormProps) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [addressLoading, setAddressLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<"basic" | "gst" | "banking">(
    "basic"
  );
  const [mapParseLatLongDebounce, setMapParseLatLongDebounce] = useState<any>();
  const [validGoogleMapLink, setValidGoogleMapLink] = useState(false);
  const [latLongLoading, setLatLongLoading] = useState(false);

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = useState(false);

    const values = Form.useWatch([], form);

    useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable || !validGoogleMapLink}
        loading={latLongLoading}
      >
        Submit
      </Button>
    );
  };

  const onSubmit = useCallback(
    async (values: Record<string, string>) => {
      const result = await editVendor(vendor.id, values);
      if (result) {
        messageApi.success("Successfully added vendor");
        form.resetFields();
      } else {
        messageApi.error("Could not add vendor. Try again");
      }
      refresh();
      onClose();
    },
    [vendor.id, refresh, onClose, messageApi, form]
  );

  const setPanFromGST = (gst: any) => {
    if (gst?.length >= 15) {
      const pan = gst.slice(2, 12);
      form.setFieldValue("pan_number", pan);
    }
  };

  const setAddressFromPincode = (pincode: any, prefix = "", suffix = "") => {
    if (pincode?.length >= 6) {
      setAddressLoading(true);
      getAddressFromPincode(pincode)
        .then((res) => {
          if (res) {
            form.setFieldValue(prefix + "city" + suffix, res?.city);
            form.setFieldValue(prefix + "state" + suffix, res?.state);
            form.setFieldValue(prefix + "country" + suffix, res?.country);
          } else {
            message.error("Pincode doesn't exist");
          }
        })
        .finally(() => {
          setAddressLoading(false);
        });
    }
  };

  useEffect(() => {
    getVendor(vendor.id).then((vendor) => {
      if (!vendor) {
        messageApi.error("Could not fetch vendor details. Try again");
        onClose();
        return;
      }
      Object.keys(vendor).forEach((key) => {
        form.setFieldValue(key, vendor[key as keyof IVendor]);
      });
    });
  }, [vendor]);

  return (
    <div className="">
      {contextHolder}
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onSubmit}
      >
        <div style={{ display: currentStep === "basic" ? "block" : "none" }}>
          <h6>Business Details</h6>
          <Form.Item
            name="name"
            label="Business Name"
            rules={[
              {
                required: true,
                message: "Name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            required
            name="vendor_type"
            label="Vendor Type"
            rules={[
              {
                required: true,
                message: "Vendor Type is required",
              },
            ]}
          >
            <Select>
              <Select.Option value="supplier">Supplier</Select.Option>
              <Select.Option value="contractor">Contractor</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="contact"
            label="Contact Number"
            rules={[
              {
                pattern: /^[0-9]{10}$/gi,
                message: "Only numbers are allowed",
              },
              {
                required: true,
                message: "Contact Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="contact_person"
            label="Contact Person"
            rules={[
              {
                required: true,
                message: "Contact Person is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email ID"
            rules={[
              {
                type: "email",
                message: "Please enter a valid email",
              },
              {
                required: true,
                message: "Email is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="designation"
            label="Designation"
            rules={[
              {
                required: true,
                message: "Designation is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Whatsapp group link"
            name="whatsapp_group_link"
            rules={[
              {
                type: "url",
                message: "Please enter a valid URL",
              },
              {
                required: true,
                message: "Whatsapp group link is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Periskope Chat ID"
            name="periskope_chat_id"
            rules={[
              {
                required: true,
                message: "Periskope Chat ID is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <div style={{ display: currentStep === "gst" ? "block" : "none" }}>
          <h6>GST Details</h6>
          <Form.Item
            name="gst"
            label="GST"
            rules={[
              {
                pattern:
                  /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/g,
                message: "Enter a valid GST Number",
              },
              {
                required: true,
                message: "GST Number is required",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setPanFromGST(e.target.value);
              }}
            />
          </Form.Item>
          <Form.Item
            name="pan_number"
            label="PAN Number"
            rules={[
              {
                pattern: /[A-Z]{5}\d{4}[A-Z]{1}/g,
                message: "Enter a valid PAN Number",
              },
              {
                required: true,
                message: "PAN Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="billing_address"
            label="Billing Address"
            rules={[
              {
                required: true,
                message: "Billing Address is required",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            required
            name="pincode"
            label="Pincode"
            rules={[
              {
                pattern: /^[1-9]\d{5}$/gi,
                message: "Enter a valid pincode",
              },
              {
                required: true,
                message: "Pincode is required",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                setAddressFromPincode(e.target.value);
              }}
            />
          </Form.Item>
          {addressLoading && <p>Loading address...</p>}
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "City is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "State is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="country"
            label="Country"
            rules={[
              {
                required: true,
                message: "Country is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="google_map"
            label="Google Map"
            rules={[
              {
                required: true,
                message: "Google Map is required",
              },
            ]}
          >
            <Input
              onChange={(e) => {
                clearTimeout(mapParseLatLongDebounce);
                setMapParseLatLongDebounce(
                  setTimeout(async () => {
                    setLatLongLoading(true);
                    const response = await getLatLongFromGooGlLink(
                      e.target.value
                    );
                    setLatLongLoading(false);
                    if (!response) {
                      message.error("Enter a valid google map link");
                      setValidGoogleMapLink(false);
                      return;
                    }

                    form.setFieldValue("lat", response?.lat);
                    form.setFieldValue("lng", response?.long);
                    setValidGoogleMapLink(true);
                  }, 500)
                );
              }}
            />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="lat"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Latitude"
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lng"
                rules={[
                  {
                    required: true,
                  },
                ]}
                label="Longitude"
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div style={{ display: currentStep === "banking" ? "block" : "none" }}>
          <h6>Banking Details</h6>
          <Form.Item
            name="bank_account_number"
            label="Bank Account Number"
            rules={[
              {
                required: true,
                message: "Bank Account Number is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="bank_account_holder"
            label="Name on Account"
            rules={[
              {
                required: true,
                message: "Name on Account is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="bank_ifsc"
            label="IFSC Code"
            rules={[
              {
                required: true,
                message: "IFSC Code is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="bank_account_type"
            label="Account Type"
            rules={[
              {
                required: true,
                message: "Account Type is required",
              },
            ]}
          >
            <Select>
              <Select.Option value="savings">Savings</Select.Option>
              <Select.Option value="current">Current</Select.Option>
              <Select.Option value="others">Others</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <Divider />
        {currentStep === "banking" ? (
          <Space>
            <Button
              onClick={() => {
                setCurrentStep("gst");
              }}
            >
              Previous
            </Button>
            <SubmitButton form={form} />
          </Space>
        ) : (
          <Space>
            <Button
              onClick={() => {
                if (currentStep === "basic") {
                  // onClose();
                } else if (currentStep === "gst") {
                  setCurrentStep("basic");
                } else if (currentStep === "banking") {
                  setCurrentStep("gst");
                }
              }}
              disabled={currentStep === "basic"}
            >
              Previous
            </Button>
            <Button
              onClick={() => {
                if (currentStep === "basic") {
                  setCurrentStep("gst");
                } else if (currentStep === "gst") {
                  setCurrentStep("banking");
                }
              }}
            >
              Next
            </Button>
          </Space>
        )}
      </Form>
    </div>
  );
}
