import { Tooltip } from "antd";
import { UserProperty } from ".";

export interface ClientPropertiesDetailsProps {
  properties: UserProperty[];
}

export function ClientPropertiesDetails({
  properties,
}: ClientPropertiesDetailsProps) {
  if (!properties || properties.length === 0) {
    return null;
  }

  return (
    <div className="">
      <div>
        <div className="align-middle th-font-size-18 th-font-weight-600 mb-3 primary-color-text">
          User Properties
        </div>
      </div>
      {properties?.map((property) => {
        return (
          <div className="row" key={property?.id}>
            <div className="col-4 th-font-weight-500">
              {property?.property?.name}
            </div>
            <div className="col-7">
              <div className="d-flex">
                <div className="pe-1">:</div>
                <div
                  style={{
                    // Ellipsis
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {property?.value?.includes("https://") ? (
                    <a href={property?.value} target="_blank" rel="noreferrer">
                      {property?.value}
                    </a>
                  ) : (
                    <Tooltip title={property?.value}>{property?.value}</Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
