import { Button } from "antd";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { clientBMCarts, fetchClientDetails } from "../../../../apis/client.api";

export function MobileCartDetails() {
  const { userId } = useParams();
  const [clientDetails, setClientDetails] = useState<any>();
  const [carts, setCarts] = useState<any[]>([]);
  const [clientDetailsForPage, setClientDetailsForPage] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchClientDetails(userId).then((res) => {
      setClientDetails(res);
    });
  }, [userId]);

  useEffect(() => {
    if (!clientDetails) return;

    setLoading(true);
    clientBMCarts({
      search: clientDetails?.contact,
    }).then((res) => {
      setCarts(res?.data ?? []);
      setClientDetailsForPage(res?.user_details ?? {});
      setLoading(false);
    });
  }, [clientDetails]);

  const totalPrice = carts.reduce((acc, cart) => {
    return acc + Number(cart?.quantity) * Number(cart?.variant?.md_price_gst);
  }, 0);

  const totalItems = carts.reduce((acc, cart) => {
    return acc + Number(cart?.quantity);
  }, 0);

  const getUserDetails = (userId: string) => {
    return clientDetailsForPage?.find((client) => client.id === userId);
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold mb-3">
          {clientDetails?.f_name} {clientDetails?.l_name}
        </h3>
        <Link to="/carts">
          <Button type="link" size="small">
            Back
          </Button>
        </Link>
      </div>

      {loading && (
        <div className="text-center alert alert-info">Loading...</div>
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: ".5rem 0",
        }}
        className="mb-4 rounded shadow p-3"
      >
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Cart Number
          </div>
          {getUserDetails(userId as string)?.user_info?.cart_number}
        </div>
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Total Price
          </div>
          Rs. {totalPrice?.toFixed(2)}
        </div>
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Total Items
          </div>
          {totalItems}
        </div>
        <div>
          <div className="text-muted fw-semibold" style={{ fontSize: 12 }}>
            Total Products
          </div>
          {carts?.length}
        </div>
      </div>
      <h5 className="fw-semibold mb-3">Cart Items</h5>
      {carts?.map((cart) => (
        <div className="rounded border mb-3 p-3" key={cart?.id}>
          <h6>{cart?.variant?.product_name}</h6>
          <div>
            <span className="fw-semibold">Quantity:</span> {cart?.quantity}
          </div>
          <div>
            <span className="fw-semibold">Price:</span>{" "}
            {cart?.variant?.md_price_gst}
          </div>
        </div>
      ))}
    </div>
  );
}
