import axios from "axios";
import { errorActions } from "../helpers/auth_helper";

export const fetchUserDetails = async () => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/procurement-current-user/`)
      .then((res) => {
        const user = res.data;
        return user;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status !== 200) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {}
};

export const fetchBookings = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/book-a-slot/`, {
        ...params,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {}
};

export const getUserCumulativeSum = async (params: any) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/user-order-sum/`, {
        ...params,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {}
};

export const getUserPropertyList = async () => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/user-info-property/`)
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {}
};

export const getUserProperties = async (userId: string) => {
  try {
    return axios
      .get(`https://api-dev2.materialdepot.in/apiV1/user-property/`, {
        params: {
          user_id: userId,
        },
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return;
      });
  } catch (error) {
    return {};
  }
};

export const updateOrCreateUserProperty = async (data: any) => {
  try {
    return axios
      .post(`https://api-dev2.materialdepot.in/apiV1/user-property/`, {
        ...data,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return false;
      });
  } catch (error) {
    return false;
  }
};

export const incrementUserVisit = async (
  userId: string,
  sales_poc?: string
) => {
  try {
    return axios
      .post(`https://api-dev2.materialdepot.in/apiV1/increment-user-visit/`, {
        user_id: userId,
        sales_poc,
      })
      .then((res) => {
        const data = res.data;
        return data;
      })
      .catch((error) => {
        errorActions(error, "Error fetching details. Please try again.");
        if (error.response?.status === 409) {
          return {
            message: "User visit recorded within the past 12 hours already.",
          };
        }
        if (error.response?.status == 403) {
          return window.location.replace("/login");
        }
        return false;
      });
  } catch (error) {
    return false;
  }
};
