import { CheckCircleFilled, WarningFilled } from "@ant-design/icons";
import {
  Button,
  Collapse,
  DatePicker,
  Empty,
  Input,
  message,
  Popconfirm,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { StatusTypes } from "..";
import {
  editDelivery,
  getDeliveries,
  importLoginextTrip,
  pushTripToPidgeWithName,
} from "../../../../apis/misc.api";
import { editPurchaseOrder } from "../../../../apis/po.api";

export function TrackerByDeliveries() {
  const [deliveries, setDeliveries] = useState<any[]>([]);
  const [currentTime, setCurrentTime] = useState(moment());
  const [dateStart, setDateStart] = useState<any>();
  const [dateEnd, setDateEnd] = useState<any>();

  const [rawTodayDeliveries, setRawTodayDeliveries] = useState<any[]>([]);

  const [tripName, setTripName] = useState<string>();
  const [poNumber, setPoNumber] = useState<string>();
  const [leadId, setLeadId] = useState<string>();
  const [assignedTo, setAssignedTo] = useState<string>();

  const [loading, setLoading] = useState(false);

  const [assignedToOptions, setAssignedToOptions] = useState<Array<string>>([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment());
    }, 60 * 1000);

    const date = moment();
    const today = date.startOf("day").format("YYYY-MM-DD HH:mm:ss");
    const tomorrow = date
      .add(1, "day")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");

    setDateStart(today);
    setDateEnd(tomorrow);

    return () => clearInterval(interval);
  }, []);

  const fetchDeliveries = useCallback(async () => {
    // Get date in format of YYYY-MM-DD 00:00:00
    // And tomorrow date in format of YYYY-MM-DD 00:00:00
    // const today = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
    // const tomorrow = moment()
    //   .add(1, "day")
    //   .startOf("day")
    // //   .format("YYYY-MM-DD HH:mm:ss");
    // const today = "2026-11-30 00:00:00";
    // const tomorrow = "2026-12-01 00:00:00";

    if (!dateStart || !dateEnd) {
      return;
    }

    setLoading(true);
    const todayDeliveries = await getDeliveries({
      po__dispatch_time__lte: dateEnd,
      po__dispatch_time__gte: dateStart,
      page_size: 1000,
    });

    setRawTodayDeliveries(todayDeliveries?.results);

    // Create a set of all assigned_to's f_name
    const assignedToSet = new Set<string>(
      todayDeliveries?.results?.map(
        (delivery: any) => delivery?.assigned_to?.f_name
      )
    );
    setAssignedToOptions(Array.from(assignedToSet));

    setLoading(false);
  }, [dateStart, dateEnd]);

  const reImportTrip = useCallback(
    (tripName: string) => async () => {
      message.loading({
        content: "Re-importing trip",
        key: "reImportTrip",
      });
      await importLoginextTrip([tripName]);
      message.success({
        content: "Trip re-imported. Reload to view changes.",
        key: "reImportTrip",
      });
    },
    []
  );

  useEffect(() => {
    setDeliveries(
      rawTodayDeliveries?.filter((delivery: any) => {
        // return !delivery?.is_deleted && delivery?.last_updated_status !== "delivered"
        if (delivery?.is_deleted) {
          return false;
        }
        if (
          delivery?.last_updated_status === "delivered" ||
          delivery?.last_updated_status === "push_error" ||
          delivery?.last_updated_status === "cancelled"
        ) {
          return false;
        }

        if (
          tripName &&
          !delivery?.trip_name?.toLowerCase()?.includes(tripName.toLowerCase())
        ) {
          return false;
        }

        if (
          poNumber &&
          !delivery?.po?.po_number
            ?.toLowerCase()
            ?.includes(poNumber.toLowerCase())
        ) {
          return false;
        }

        if (
          leadId &&
          !delivery?.lead_id?.toLowerCase()?.includes(leadId.toLowerCase())
        ) {
          return false;
        }

        if (
          assignedTo &&
          !delivery?.assigned_to?.f_name
            ?.toLowerCase()
            ?.includes(assignedTo.toLowerCase())
        ) {
          return false;
        }

        return true;
      }) || []
    );
  }, [rawTodayDeliveries, tripName, poNumber, leadId, assignedTo]);

  useEffect(() => {
    fetchDeliveries();
  }, [fetchDeliveries]);

  // Re-fetch data every 5 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      fetchDeliveries();
    }, 5 * 60 * 1000);

    return () => clearInterval(interval);
  }, [fetchDeliveries]);

  const groupedTrips = useMemo(() => {
    return deliveries.reduce((acc, delivery) => {
      const tripName = delivery.trip_name ?? "Unassigned";
      if (!acc[tripName]) {
        acc[tripName] = [];
      }

      acc[tripName].push(delivery);

      return acc;
    }, {} as any);
  }, [deliveries]);

  const keepOpen = useMemo(() => {
    return Boolean(tripName || poNumber || leadId);
  }, [leadId, poNumber, tripName]);

  const assignTrip = useCallback(
    (tripName: string) => async () => {
      message.loading({
        content: "Assigning trip to Pidge",
        key: "assigningTrip",
      });
      const pushResponse = await pushTripToPidgeWithName(tripName);
      if (pushResponse?.success === false) {
        message.error({
          content: pushResponse?.message,
          key: "assigningTrip",
        });
        return;
      }

      message.success({
        content: "Trip assigned to Pidge",
        key: "assigningTrip",
      });
    },
    []
  );

  const reImportAllTrips = useCallback(async () => {
    const allTripNames = Object.keys(groupedTrips).filter((tripName) => {
      return tripName !== "Unassigned";
    });

    message.loading({
      content: "Re-importing all trips",
      key: "reImportAllTrips",
    });

    await importLoginextTrip(allTripNames);

    message.success({
      content: "All trips re-imported. Reload to view changes.",
      key: "reImportAllTrips",
    });
  }, [groupedTrips]);

  const editDeliveryStatus = useCallback(
    async (id: number, status: string) => {
      try {
        editDelivery({
          id: id,
          last_updated_status: status,
        });
        message.success("Delivery status updated successfully");

        fetchDeliveries();
      } catch (e) {
        message.error("Failed to update delivery status");
      }
    },
    [fetchDeliveries]
  );

  const editPO = useCallback((poId: string | number, status: string) => {
    editPurchaseOrder(poId, {
      po_delivery_status: status,
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "PO Number",
        key: "po.po_number",
        render: (record: any) => {
          return record.po?.po_number;
        },
      },
      {
        title: "Lead ID",
        key: "lead_id",
        dataIndex: "lead_id",
      },
      {
        title: "PO Status",
        key: "po_status",
        render: (record: any) => {
          return (
            // <Select
            //   value={record?.last_updated_status}
            //   style={{ width: 140 }}
            //   onChange={(value) => {
            //     editDeliveryStatus(record.id, value);
            //   }}
            //   disabled={record?.last_updated_status === "delivered"}
            // >
            //   <Select.Option value="created">Created</Select.Option>
            //   <Select.Option value="picked_up">Picked Up</Select.Option>
            //   <Select.Option value="dispatched">Picked Up</Select.Option>
            //   <Select.Option value="delivered">Delivered</Select.Option>
            //   <Select.Option value="pickup_attempted">
            //     Pickup Attempted
            //   </Select.Option>
            //   <Select.Option value="delivery_attempted">
            //     Delivery Attempted
            //   </Select.Option>
            // </Select>
            <Select
              value={record?.po?.po_delivery_status}
              style={{ width: 140 }}
              onChange={(value) => {
                editPO(record?.po?.id, value);
                // Refresh the page
                fetchDeliveries();
              }}
            >
              {StatusTypes.map((status) => (
                <Select.Option value={status?.value} key={status?.value}>
                  {status?.label}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        title: "Vendor Name",
        key: "po.vendor_name",
        render: (record: any) => {
          return <div style={{ width: 170 }}>{record?.vendor_data?.name}</div>;
        },
      },
      // {
      //   title: "Delivery Person",
      //   dataIndex: "delivery_person_name",
      //   key: "delivery_person_name",
      // },
      {
        title: "Pickup Planned ETA",
        dataIndex: "pickup_planned_eta",
        render: (data: any, record: any) => {
          const date = moment(data).format("YYYY-MM-DD HH:mm:ss");

          const diff = moment(currentTime).diff(
            record?.pickup_planned_eta,
            "minutes"
          );

          if (record?.trip_name) {
            const currentSequence = record?.pickup_sequence;

            // Get last touchpoint by sorting by modified_at of the delivery record and figure out which time to consider
            // by considering deliver_time if present else pickup_time
            const pickupsAndDeliveries = groupedTrips[record.trip_name];
            const lastTouchPoint = pickupsAndDeliveries
              .filter((point: any) => {
                return (
                  point.pickup_sequence < currentSequence &&
                  (point.pickup_time || point.delivery_time)
                );
              })
              .sort((a: any, b: any) => {
                return moment(a.modified_at).diff(moment(b.modified_at));
              })
              .pop();

            const timeToConsider =
              lastTouchPoint?.delivery_time || lastTouchPoint?.pickup_time;
            const timeToDiffAgainst = lastTouchPoint?.delivery_time
              ? lastTouchPoint?.delivery_planned_eta
              : lastTouchPoint?.pickup_planned_eta;

            const previousPickupDiff = lastTouchPoint
              ? moment(timeToConsider).diff(
                  moment(timeToDiffAgainst),
                  "minutes"
                )
              : 0;

            const totalDiff = diff + previousPickupDiff;

            return (
              <>
                {date}{" "}
                {!record?.pickup_time && (
                  <>
                    (Δ {totalDiff} mins){" "}
                    {totalDiff > 20 && (
                      <WarningFilled style={{ color: "red" }} />
                    )}
                  </>
                )}
              </>
            );
          }

          return (
            <>
              {date}{" "}
              {!record?.pickup_time && (
                <>
                  (Δ {diff} mins){" "}
                  {diff > 20 && <WarningFilled style={{ color: "red" }} />}
                </>
              )}
            </>
          );
        },
      },
      {
        title: "Actual Pickup Time",
        dataIndex: "pickup_time",
        render: (data: any, record?: any) => {
          if (!data) {
            return <>--</>;
          }

          const date = moment(data).format("HH:mm:ss");
          return (
            <Space>
              <span>{date}</span>
              <span>
                (Δ{" "}
                {moment(data).diff(
                  moment(record?.pickup_planned_eta),
                  "minutes"
                )}{" "}
                mins)
              </span>
              {moment(data).diff(
                moment(record?.pickup_planned_eta),
                "minutes"
              ) > 20 ? (
                <WarningFilled style={{ color: "red" }} />
              ) : (
                <CheckCircleFilled style={{ color: "green" }} />
              )}
            </Space>
          );
        },
      },
      {
        title: "Delivery Planned ETA",
        dataIndex: "delivery_planned_eta",
        render: (data: any, record: any) => {
          const date = moment(data).format("HH:mm:ss");

          const diff = currentTime.diff(
            moment(record?.delivery_planned_eta),
            "minutes"
          );

          if (record?.trip_name) {
            const currentSequence = record?.delivery_sequence;

            // Get last touchpoint by sorting by modified_at of the delivery record and figure out which time to consider
            // by considering deliver_time if present else pickup_time
            const pickupsAndDeliveries = groupedTrips[record.trip_name];
            const lastTouchPoint = pickupsAndDeliveries
              .filter((point: any) => {
                return (
                  point.pickup_sequence < currentSequence &&
                  (point.pickup_time || point.delivery_time)
                );
              })
              .sort((a: any, b: any) => {
                return moment(a.modified_at).diff(moment(b.modified_at));
              })
              .pop();

            const timeToConsider =
              lastTouchPoint?.delivery_time || lastTouchPoint?.pickup_time;
            const timeToDiffAgainst = lastTouchPoint?.delivery_time
              ? lastTouchPoint?.delivery_planned_eta
              : lastTouchPoint?.pickup_planned_eta;

            const previousDeliveryDiff = lastTouchPoint
              ? moment(timeToConsider).diff(
                  moment(timeToDiffAgainst),
                  "minutes"
                )
              : 0;

            const totalDiff = diff + previousDeliveryDiff;

            return (
              <>
                {date}{" "}
                {!record?.delivery_time && (
                  <>
                    (Δ {totalDiff} mins){" "}
                    {totalDiff > 20 && (
                      <WarningFilled style={{ color: "red" }} />
                    )}
                  </>
                )}
              </>
            );
          }

          return (
            <>
              {date}{" "}
              {!record?.delivery_time && (
                <>
                  (Δ {diff} mins){" "}
                  {diff > 20 && <WarningFilled style={{ color: "red" }} />}
                </>
              )}
            </>
          );
        },
      },
      {
        title: "Actual Delivery Time",
        dataIndex: "delivery_time",
        render: (data: any, record?: any) => {
          if (!data) {
            return <>--</>;
          }

          const date = moment(data).format("HH:mm:ss");

          const diff = moment(data).diff(
            moment(record?.delivery_planned_eta),
            "minutes"
          );

          return (
            <Space>
              <span>{date}</span>
              <span>(Δ {diff} mins)</span>
              {diff > 20 ? (
                <WarningFilled style={{ color: "red" }} />
              ) : (
                <CheckCircleFilled style={{ color: "green" }} />
              )}
            </Space>
          );
        },
      },
      {
        title: "Actions",
        key: "actions",
        render: (record: any) => {
          return (
            <Space>
              <Link to={`/po-details/${record?.po?.id}`}>
                <Button size="small" type="default">
                  View Details
                </Button>
              </Link>
              {record?.po?.live_location_link && (
                <a
                  href={record?.po?.live_location_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button size="small" type="default">
                    Live Location
                  </Button>
                </a>
              )}
            </Space>
          );
        },
      },
    ],
    [currentTime, editPO, fetchDeliveries, groupedTrips]
  );

  const lastPickedUpDelivery = useMemo(() => {
    // Sort by pickup_sequence and get the last delivery with pickup_time
    return deliveries
      .filter((delivery) => delivery.pickup_time)
      .sort((a, b) => a.pickup_sequence - b.pickup_sequence)
      .pop();
  }, [deliveries]);

  const nextPickupDelivery = useCallback(
    (tripName: string) => {
      // Sort by pickup_sequence and get the first delivery without pickup_time
      // return deliveries
      //   .filter((delivery) => !delivery.pickup_time)
      //   .sort((a, b) => a.pickup_sequence - b.pickup_sequence)
      //   .shift();
      return groupedTrips[tripName]
        .filter((delivery: any) => !delivery.pickup_time)
        .sort((a: any, b: any) => a.pickup_sequence - b.pickup_sequence)
        .shift();
    },
    [groupedTrips]
  );

  const lastDeliveredDelivery = useMemo(() => {
    // Sort by delivery_sequence and get the last delivery with delivery_time
    return deliveries
      .filter((delivery) => delivery.delivery_time)
      .sort((a, b) => a.delivery_sequence - b.delivery_sequence)
      .pop();
  }, [deliveries]);

  const nextDelivery = useCallback(
    (tripName: string) => {
      // Sort by delivery_sequence and get the first delivery without delivery_time
      // return deliveries
      //   .filter((delivery) => !delivery.delivery_time)
      //   .sort((a, b) => a.delivery_sequence - b.delivery_sequence)
      //   .shift();
      return groupedTrips[tripName]
        .filter((delivery: any) => !delivery.delivery_time)
        .sort((a: any, b: any) => a.delivery_sequence - b.delivery_sequence)
        .shift();
    },
    [groupedTrips]
  );

  const isTripNotProper = useCallback(
    (tripName: string) => {
      // Check if the trip has any delivery with a lower sequence number but has another delivery with a higher sequence number
      // and has reached a picked_up state or delivered state
      const deliveries = groupedTrips[tripName];
      const pickupSorted = deliveries
        .filter((delivery: any) => delivery.pickup_time)
        .sort((a: any, b: any) => a.pickup_sequence - b.pickup_sequence);

      const deliverySorted = deliveries
        .filter((delivery: any) => delivery.delivery_time)
        .sort((a: any, b: any) => a.delivery_sequence - b.delivery_sequence);

      const lastPickedUp = pickupSorted.pop();
      const lastDelivered = deliverySorted.pop();

      if (!lastPickedUp || !lastDelivered) {
        return false;
      }

      return (
        lastPickedUp.pickup_sequence < lastDelivered.pickup_sequence ||
        lastPickedUp.delivery_sequence < lastDelivered.delivery_sequence
      );
    },
    [groupedTrips]
  );

  return (
    <main>
      <div className="mb-3">
        <Space>
          <Popconfirm
            title="This will reset all deliveries to track from loginext. Are you sure?"
            onConfirm={reImportAllTrips}
          >
            <Button type="default">Re-import All Trips</Button>
          </Popconfirm>
          <Typography.Text>Current Time:</Typography.Text>
          <Typography.Text>
            {currentTime.format("YYYY-MM-DD HH:mm:ss")}
          </Typography.Text>
          <Typography.Text>Dispatch Date:</Typography.Text>
          {/* <DatePicker
            onChange={(date) => {
              const start = date?.startOf("day").format("YYYY-MM-DD HH:mm:ss");
              const end = date
                ?.add(1, "day")
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
              setDateStart(start);
              setDateEnd(end);
            }}
          /> */}
          {/* Range Date Picker */}
          <DatePicker.RangePicker
            onChange={(dates) => {
              if (!dates) {
                return;
              }

              const start = dates[0]
                ?.startOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
              const end = dates[1]
                ?.add(1, "day")
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
              setDateStart(start);
              setDateEnd(end);
            }}
          />
        </Space>
      </div>
      <div className="d-flex justify-content-end align-items-center mb-3">
        <Space>
          <div>
            <div style={{ fontSize: 10 }}>Trip Name</div>
            <Input.Search
              onSearch={(val) => {
                setTripName(val);
              }}
            />
          </div>
          <div>
            <div style={{ fontSize: 10 }}>PO Number</div>
            <Input.Search
              onSearch={(val) => {
                setPoNumber(val);
              }}
            />
          </div>
          <div>
            <div style={{ fontSize: 10 }}>Lead ID</div>
            <Input.Search
              onSearch={(val) => {
                setLeadId(val);
              }}
            />
          </div>
          <div>
            <div style={{ fontSize: 10 }}>Assigned To</div>
            <Select
              style={{ width: 160 }}
              onChange={(value) => {
                if (!value) {
                  setAssignedTo(undefined);
                  return;
                }

                setAssignedTo(value);
              }}
              allowClear
            >
              {assignedToOptions.map((option) => (
                <Select.Option value={option} key={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Space>
        <div className="ms-3">
          <Spin spinning={loading} />
        </div>
      </div>
      {groupedTrips && Object.keys(groupedTrips).length > 0 ? (
        <Collapse defaultActiveKey={keepOpen ? Object.keys(groupedTrips) : []}>
          {Object.keys(groupedTrips).map((tripName) => {
            return (
              <Collapse.Panel
                header={
                  <div
                    className="d-flex justify-content-between"
                    style={
                      {
                        // backgroundColor: isTripNotProper(tripName)
                        //   ? "red"
                        //   : "rgba(230, 0, 0, 0.3)",
                      }
                    }
                  >
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Trip
                      </Typography.Text>
                      {tripName}
                      {isTripNotProper(tripName) && (
                        <WarningFilled style={{ color: "red" }} />
                      )}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Service
                      </Typography.Text>
                      {/* {groupedTrips[tripName]?.[0]?.service} */}
                      {Array.from(
                        new Set(
                          groupedTrips[tripName].map((x: any) => x?.service)
                        )
                      ).join(", ")}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Assigned To
                      </Typography.Text>
                      {Array.from(
                        new Set(
                          groupedTrips[tripName].map(
                            (x: any) => x?.assigned_to?.f_name
                          )
                        )
                      )
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Delivery Person
                      </Typography.Text>
                      {Array.from(
                        new Set(
                          groupedTrips[tripName].map(
                            (x: any) => x?.delivery_person_name
                          )
                        )
                      )
                        .filter(Boolean)
                        .join(", ")}
                    </span>
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Next Pickup Planned ETA
                      </Typography.Text>
                      {nextPickupDelivery(tripName)
                        ? moment(
                            nextPickupDelivery(tripName).pickup_planned_eta
                          ).format("YYYY-MM-DD HH:mm:ss")
                        : "No more pickups"}{" "}
                      {nextPickupDelivery(tripName)
                        ? "(Δ " +
                          moment(currentTime).diff(
                            nextPickupDelivery(tripName).pickup_planned_eta,
                            "minutes"
                          ) +
                          ")"
                        : null}
                      {nextPickupDelivery(tripName) &&
                        moment(currentTime).diff(
                          nextPickupDelivery(tripName).pickup_planned_eta,
                          "minutes"
                        ) > 20 && (
                          <WarningFilled
                            style={{ color: "red", marginLeft: 8 }}
                          />
                        )}
                    </span>
                    {/* <span>
                    <Typography.Text
                      strong
                      className="mb-0 d-block"
                      style={{ fontSize: 12 }}
                    >
                      Last Pickup Actual Time
                    </Typography.Text>
                    {lastPickedUpDelivery
                      ? moment(lastPickedUpDelivery.pickup_time).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : "No pickups yet"}
                  </span> */}
                    <span style={{ flexGrow: 1, width: "16.6%" }}>
                      <Typography.Text
                        strong
                        className="mb-0 d-block"
                        style={{ fontSize: 12 }}
                      >
                        Next Delivery Planned ETA
                      </Typography.Text>
                      {nextDelivery(tripName)
                        ? moment(
                            nextDelivery(tripName).delivery_planned_eta
                          ).format("YYYY-MM-DD HH:mm:ss")
                        : "No more deliveries"}{" "}
                      (Δ{" "}
                      {/* {nextDelivery && (
                          <>
                            {currentTime.isAfter(
                              moment(nextDelivery.delivery_planned_eta)
                            )
                              ? "+"
                              : "-"}
                          </>
                        )} */}
                      {nextDelivery(tripName) && (
                        <>
                          {moment(currentTime).diff(
                            nextDelivery(tripName).delivery_planned_eta,
                            "minutes"
                          )}
                        </>
                      )}
                      )
                      {nextDelivery(tripName) &&
                        moment(currentTime).diff(
                          nextDelivery(tripName)?.delivery_planned_eta,
                          "minutes"
                        ) > 20 && (
                          <WarningFilled
                            style={{ color: "red", marginLeft: 8 }}
                          />
                        )}
                    </span>
                    {/* <span>
                    <Typography.Text
                      strong
                      className="mb-0 d-block"
                      style={{ fontSize: 12 }}
                    >
                      Last Delivery Actual Time
                    </Typography.Text>
                    {lastDeliveredDelivery
                      ? moment(lastDeliveredDelivery.delivery_time).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : "No deliveries yet"}
                  </span> */}
                  </div>
                }
                key={tripName}
              >
                <Table
                  columns={columns}
                  dataSource={groupedTrips[tripName]?.sort((a: any, b: any) => {
                    return a.pickup_sequence - b.pickup_sequence;
                  })}
                  pagination={false}
                  scroll={{ x: "100%" }}
                />
                {tripName !== "Unassigned" && (
                  <>
                    <hr />
                    <div className="d-flex justify-content-end align-items-center mt-4">
                      <Space>
                        <Button type="default" onClick={assignTrip(tripName)}>
                          Push to Pidge
                        </Button>
                        <Button type="default" onClick={reImportTrip(tripName)}>
                          Re-import Trip
                        </Button>
                      </Space>
                    </div>
                  </>
                )}
              </Collapse.Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty />
      )}
    </main>
  );
}
