import { Button } from "antd";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { addClient } from "../../../../apis/client.api";
import AddClientForm from "../../../../components/client/add_client_form";

const userTypesTabs = [
  {
    label: "Individual",
    value: "individual",
  },
  {
    label: "Business",
    value: "business",
  },
];

export function MobileClientAdd() {
  const navigate = useNavigate();
  const [userType, setUserType] = useState<"individual" | "business">(
    "individual"
  );

  const [searchParams] = useSearchParams();

  const onFinish = (values: any) => {
    addClient({
      ...values,
      roles: "10",
    }).then(() => {
      navigate("/clients");
    });
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="">
          <h3 className="fw-bold">Add Client</h3>
        </div>
        <div>
          <Link to="/clients">
            <Button type="link">Back</Button>
          </Link>
        </div>
      </div>
      <AddClientForm initContact={searchParams.get("contact") ?? undefined} />
    </div>
    // <Form onFinish={onFinish} className="p-3">
    //   <Form.Item
    //     label="Phone"
    //     rules={[
    //       { required: true, message: "Please input your phone number!" },
    //       {
    //         pattern: new RegExp(/^[0-9]{10}$/),
    //         message: "Please enter a valid phone number",
    //       },
    //     ]}
    //     name="contact"
    //   >
    //     <Input type="tel" />
    //   </Form.Item>
    //   <div className="d-flex justify-content-center w-100">
    //     <Segmented
    //       options={userTypesTabs}
    //       value={userType}
    //       onChange={(v) => setUserType(v as any)}
    //     />
    //   </div>
    //   {userType === "business" && (
    //     <Form.Item label="GST No." name="gst_no">
    //       <Input name="gst_no" placeholder="GST No." />
    //     </Form.Item>
    //   )}
    //   <label className="d-block th-fontSize-12 th-fontWeight-500 text-muted mb-1">
    //     Name
    //   </label>
    //   <div className="d-flex gap-1">
    //     <Form.Item name="salutation">
    //       <Select
    //         options={[
    //           { label: "Mr.", value: "mr" },
    //           { label: "Ms.", value: "ms" },
    //           { label: "Mx.", value: "mx" },
    //         ]}
    //         placeholder="Title"
    //         onChange={(v) => console.log(v)}
    //         style={{ width: 80, maxWidth: 80 }}
    //       />
    //     </Form.Item>
    //     <Form.Item name="f_name">
    //       <Input placeholder="First Name" />
    //     </Form.Item>
    //     <Form.Item name="l_name">
    //       <Input placeholder="Last Name" />
    //     </Form.Item>
    //   </div>
    //   <Form.Item label="Email" name="email">
    //     <Input type="email" />
    //   </Form.Item>

    //   <Button htmlType="submit" className="w-100" type="primary">
    //     Save
    //   </Button>
    // </Form>
  );
}
