import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AddClientAddressForm from "../../../../../components/client/add_client_address_form";

export function MobileClientAddressAdd() {
  const { userId } = useParams();
  const navigate = useNavigate();

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between">
        <h3 className="fw-bold mb-3">Add Address</h3>
        <Button
          type="link"
          size="small"
          onClick={() => navigate(`/client/${userId}`)}
        >
          Back
        </Button>
      </div>
      <AddClientAddressForm
        user_id={userId}
        fetchAddresses={() => {}}
        onClientAddAddressDrawerClose={() => {
          navigate(`/client/${userId}`);
        }}
      />
    </div>
  );
}
