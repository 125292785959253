import { Button, Form, FormInstance, Input, Select, Space, Tabs } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { fetchBranchMembers } from "../../../apis/branch.api";
import { assignClientBM, editClient } from "../../../apis/client.api";
import {
  getUserProperties,
  getUserPropertyList,
  updateOrCreateUserProperty,
} from "../../../apis/user.api";
import {
  ClientProperties,
  UserInfoProperty,
  UserProperty,
} from "../client_properties";

const { Option } = Select;
interface EditClientFormProps {
  client: any;
  onClientEditDrawerClose: Function;
  fetchBasicDetails: Function;
  fetchExtraDetails?: () => void;
}
const EditClientForm = ({
  client,
  onClientEditDrawerClose,
  fetchBasicDetails,
  fetchExtraDetails,
}: EditClientFormProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState<"details" | "properties">("details");
  const [properties, setProperties] = useState<UserInfoProperty[]>([]);
  const [userProperties, setUserProperties] = useState<UserProperty[]>([]);
  const [branchMembers, setBranchMembers] = useState<any[]>([]);

  useEffect(() => {
    fetchBranchMembers().then((res) => {
      setBranchMembers(res);
    });
  }, []);

  useEffect(() => {
    getUserPropertyList().then((res) => {
      setProperties(res);
    });
    getUserProperties(client?.id).then((res) => {
      setUserProperties(res);
    });
  }, [client?.id]);

  const computedUserProperties = useMemo(() => {
    return userProperties.reduce((acc, property) => {
      acc[property.property.id] = property.value;
      if (property.property.name.includes("Date") && property.value) {
        acc[property.property.id] = moment(property.value);
      }
      if (property.property.name.includes("Attachment")) {
        acc[property.property.id] = property.value;
      }
      if (property.property.name.includes("Requirements")) {
        acc[property.property.id] = property.value.split(",");
      }

      return acc;
    }, {} as Record<string, string | string[] | moment.Moment | null>);
  }, [userProperties]);

  useEffect(() => {
    if (!computedUserProperties) return;

    // Find source property
    const source = properties?.find((p) => p?.name === "Source");
    const requirements = properties?.find((p) => p?.name === "Requirements");
    const location = properties?.find((p) => p?.name === "Location");
    if (!source || !requirements || !location) return;

    const sourceProperty = computedUserProperties[source?.id];
    const requirementsProperty = computedUserProperties[requirements?.id];
    const locationProperty = computedUserProperties[location?.id];

    // Set source value
    form.setFieldsValue({
      source: sourceProperty?.toString(),
      requirements: requirementsProperty,
      location: locationProperty,
    });
  }, [computedUserProperties, properties]);

  const onFinish = (updatedValues: any) => {
    setLoading(true);
    console.log("updatedValues", updatedValues);
    const source = updatedValues?.source;
    const requirements = updatedValues?.requirements;
    const location = updatedValues?.location;

    // updatedValues.roles = 10;

    const payloadForPropertyUpdation = {
      user_id: client?.id,
      properties: [] as Array<any>,
    };

    if (source) {
      const sourceProperty = properties?.find((p) => p?.name === "Source");
      payloadForPropertyUpdation.properties.push({
        property_id: sourceProperty?.id,
        value: source,
      });

      delete updatedValues?.source;
    }

    if (requirements) {
      const requirementsProperty = properties?.find(
        (p) => p?.name === "Requirements"
      );
      payloadForPropertyUpdation.properties.push({
        property_id: requirementsProperty?.id,
        value: requirements.join(","),
      });

      delete updatedValues?.requirements;
    }

    if (location) {
      const locationProperty = properties?.find((p) => p?.name === "Location");
      payloadForPropertyUpdation.properties.push({
        property_id: locationProperty?.id,
        value: location,
      });

      delete updatedValues?.location;
    }

    if (payloadForPropertyUpdation.properties.length > 0) {
      updateOrCreateUserProperty(payloadForPropertyUpdation).then((res) => {
        console.log("Source updated", res);
      });
    }

    if (updatedValues?.bm) {
      const bmObj = branchMembers?.find((bm) => bm.id === updatedValues?.bm);
      assignClientBM(client?.id, bmObj?.id, bmObj?.user_permission);

      delete updatedValues?.bm;
    }

    editClient(
      {
        ...client,
        ...updatedValues,
      },
      client?.id
    ).then((res) => {
      setLoading(false);
      onClientEditDrawerClose();
      fetchBasicDetails();
    });
  };

  const SubmitButton = ({ form }: { form: FormInstance }) => {
    const [submittable, setSubmittable] = React.useState(false);

    // Watch all values
    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        }
      );
    }, [values]);

    return (
      <Button
        type="primary"
        htmlType="submit"
        disabled={!submittable}
        loading={loading}
      >
        Submit
      </Button>
    );
  };

  useEffect(() => {
    Object.keys(client).forEach((key: any) => {
      form.setFieldValue(key, client[key]);
    });
    form.setFieldsValue({
      bm: client?.user_manager?.manager,
    });
  }, [client]);

  const getView = () => {
    if (view === "details") {
      return (
        <Form
          form={form}
          name="validateOnly"
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
        >
          <div className="pb-3 th-font-weight-500">
            <div>Phone</div>
            <div>{client?.contact}</div>
          </div>

          {/* <Form.List name="alternate_contact">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              label={index === 0 ? "Alternate phone" : ""}
              required={false}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                rules={[
                  {
                    pattern: /^[1-9]\d{9}$/gi,
                    message: "Enter a valid phone number",
                  },
                ]}
                noStyle
              >
                <Input placeholder="Phone" style={{ width: "80%" }} />
              </Form.Item>
              {fields.length > 0 ? (
                <MinusCircleOutlined
                  className="dynamic-delete-button mx-2"
                  onClick={() => remove(field.name)}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: "60%" }}
              icon={<PlusOutlined />}
            >
              Add alternate phone
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List> */}
          <div className="d-flex gap-2">
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Enter a valid salutation",
                },
              ]}
              name="salutation"
              label="Salutation"
              style={{ width: 90 }}
            >
              <Select placeholder="Select salutation">
                <Option value="Mr">Mr</Option>
                <Option value="Mrs">Ms</Option>
                <Option value="Mx">Mx</Option>
              </Select>
            </Form.Item>
            <Form.Item
              required
              name="f_name"
              label="Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="l_name"
              label="Last Name"
              className="flex-grow-1"
              rules={
                [
                  // {
                  //   pattern: /^[a-zA-Z\s]*$/gi,
                  //   message: "Enter a valid name",
                  // }
                ]
              }
            >
              <Input />
            </Form.Item>
          </div>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                type: "email",
                message: "Enter a valid email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.List name="alternate_email">
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              label={index === 0 ? "Alternate email" : ""}
              required={false}
              key={field.key}
            >
              <Form.Item
                {...field}
                validateTrigger={["onChange", "onBlur"]}
                noStyle
              >
                <Input placeholder="Email" style={{ width: "80%" }} />
              </Form.Item>
              {fields.length > 0 ? (
                <MinusCircleOutlined
                  className="dynamic-delete-button mx-2"
                  onClick={() => remove(field.name)}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              style={{ width: "60%" }}
              icon={<PlusOutlined />}
            >
              Add alternate email
            </Button>
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List> */}
          {/* <Form.Item
      name="alternate_contact"
      label="Alternate phone"
      rules={[
        {
          pattern: /^[1-9]\d{9}$/gi,
          message: "Enter a valid phone number",
        },
      ]}
    >
      <Input type="number" maxLength={10} />
    </Form.Item>
    <Form.Item name="alternate_email" label="Alternate Email">
      <Input />
    </Form.Item> */}
          {/* <Form.Item
            label="Source"
            name={"channel"}
            rules={[
              {
                required: true,
                message: "Enter a valid user source",
              },
            ]}
          >
            <Select placeholder="Select user source">
              <Option value="whatsapp">Whatsapp/DMs</Option>
              <Option value="call">Call</Option>
              <Option value="store_walk_in">Store Walk In</Option>
              <Option value="email">Email</Option>
              <Option value="website_orders">Website Orders</Option>
            </Select>
          </Form.Item> */}
          <Form.Item
            name="source"
            label="Source"
            rules={[
              {
                required: true,
                message: "Enter a valid source",
              },
            ]}
          >
            <Select>
              {properties
                ?.find((p) => p?.name === "Source")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="requirements"
            label="Requirements"
            rules={[
              {
                required: true,
                message: "Enter a valid requirements",
              },
            ]}
          >
            <Select mode="multiple" placeholder="Select requirements">
              {properties
                ?.find((p) => p?.name === "Requirements")
                ?.options?.map((option) => {
                  return <Option value={option}>{option}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item
            name="location"
            label="Location"
            rules={[{ required: true, message: "Enter a valid location" }]}
          >
            <Input />
          </Form.Item>
          {/* <Form.Item
            label="Medium"
            name={"medium"}
            rules={[
              {
                required: true,
                message: "Enter a valid user medium",
              },
            ]}
          >
            <Select placeholder="Select user medium">
              <Option value="Fbig">Fbig</Option>
              <Option value="SEO">SEO</Option>
              <Option value="SEM">SEM</Option>
              <Option value="SMM">SMM</Option>
              <Option value="Referral">Referral</Option>
              <Option value="Offline">Offline</Option>
              <Option value="Newsletter">Email Marketing/Newsletter</Option>
            </Select>
          </Form.Item> */}
          <Form.Item name="bm" label="BM">
            <Select
              placeholder="Select BM"
              showSearch
              filterOption={(input, option) =>
                ((option?.children as unknown as string) ?? "")
                  .toLowerCase()
                  ?.indexOf(input.toLowerCase()) >= 0
              }
            >
              {branchMembers
                ?.filter((bm) => bm?.user_permission === 3)
                ?.map((bm) => {
                  return <Option value={bm.id}>{bm.user.f_name}</Option>;
                })}
            </Select>
          </Form.Item>
          <Form.Item>
            <Space>
              <SubmitButton form={form} />
            </Space>
          </Form.Item>
        </Form>
      );
    }

    if (view === "properties") {
      return (
        <ClientProperties
          userId={client?.id}
          onComplete={() => {
            onClientEditDrawerClose();
            fetchBasicDetails();
            if (fetchExtraDetails) {
              fetchExtraDetails();
            }
          }}
          isStudio={client?.roles === 8}
          client={client}
        />
      );
    }
  };

  return (
    <>
      <Tabs
        items={[
          {
            key: "details",
            label: "Basic Details",
          },
          {
            key: "properties",
            label: "+ Details",
          },
        ]}
        activeKey={view}
        onChange={(view) => setView(view as "details" | "properties")}
      />
      {getView()}
    </>
  );
};

export default EditClientForm;
